<template>
  <div id="map" class="map-container">
    <GoogleMap
      :markers="mapMarkers"
      :center="mapCenter"
      :initialCenter="mapCenter"
      :key="key"
      @marker-click="handleMarkerClick"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import GoogleMap from '../../../../components/Map.vue';

export default {
  name: 'Map',
  components: {
    GoogleMap,
  },
  data() {
    return {
      center: null,
      key: 0,
    };
  },
  props: ['clubs', 'searchLocation', 'http', 'national_id'],
  computed: {
    mapMarkers() {
      return this.clubs.map((club) => ({
        id: club._id,
        position: {
          lat: _.get(club, 'venue.address.lat', 0),
          lng: _.get(club, 'venue.address.lng', 0),
        },
        draggable: false,
        club,
      }));
    },
    mapCenter() {
      return this.center || this.defaultCenter;
    },
    defaultCenter() {
      return this.clubs.length > 0
        ? {
          lat: _.get(this.clubs[0], 'venue.address.lat', -33.8688),
          lng: _.get(this.clubs[0], 'venue.address.lng', 151.2093),
        }
        : { lat: -33.8688, lng: 151.2093 }; // Sydney's coordinates as fallback
    },
  },
  methods: {
    handleMarkerClick(marker) {
      this.$emit('selectClub', marker.club);
    },
    async geocodePostcode(postcode) {
      try {
        const response = await this.http.get(`/assemble/api/v1/widgets/geocode?postcode=${postcode}`);
        const { data } = response;
        if (data.status === 'success' && data.data) {
          this.center = {
            lat: data.data.lat,
            lng: data.data.lng,
          };
          this.key++;
        } else {
          throw new Error(data.message || 'Geocoding failed');
        }
      } catch (error) {
        console.error('Error geocoding postcode:', error);
        this.center = null;
        this.key++;
      }
    },
  },
  watch: {
    searchLocation: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          console.log('searchLocation', newValue);
          this.geocodePostcode(newValue);
        } else {
          this.center = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 600px;
}
</style>
