<template>
    <div class="results-list">
      <div v-if="clubs.length === 0" class="no-results">
        Sorry, there are no nearby clubs. Please revise your search.
      </div>
      <div v-else v-for="club in clubs" :key="club.id" class="club-item" @click="selectClub(club)" :class="{ 'selected': selectedClub === club._id }">
        <div class="d-flex club-main gap-05">
          <img :src="club.meta.imgUrl || nationalLogo " :alt="club.name" />
          <div class="d-grid">
            <h3>{{ club.name }}</h3>
            <p>{{ club.venue.address.suburb }}, {{ club.venue.address.postcode }}</p>
            <p>{{ formatNumber(club.distance) }} km away</p>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'ResultsList',
  props: ['clubs', 'national_id'],
  data() {
    return {
      selectedClub: null
    };
  },
  methods: {
    selectClub(club) {
      this.selectedClub = club._id;
      this.$emit('selectClub', club);
    },
    formatNumber(num) {
      return num.toFixed(2);
    },
  },
  computed: {
    nationalLogo() {
      const logo = process.env[`VUE_APP_${this.national_id}_LOGO_ACCOUNT`];
      return logo;
    }
  }
};
</script>

<style lang="scss" scoped>
.results-list {
    .club-item {
      cursor: pointer;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
        max-width: 5rem;
        max-height: 5rem;
        object-fit: cover;
        padding: 0.5rem;
      }

      .club-main {

      align-items: center;
        h3 { margin-bottom: 0.25rem; }
      }
      transition: all 0.3s ease;

      &.selected {
        background-color: #f0f0f0;
        border-color: #3c3c3c;
      }

      &:hover {
        border: 1px solid #3c3c3c;
      }
    }
}
</style>
