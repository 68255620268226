<template>
   <div class="tab-skeleton-container">
      <div class="tab-bar">
        <el-skeleton animated>
          <template slot="template">
            <div class="d-flex gap-05">
              <el-skeleton-item variant="text" style="width: 20%; margin-right: 1.5rem; " v-for="i in 6" :key="`small${i}`" animated class="mb-1"/>
            </div>
          </template>
        </el-skeleton>
      </div>
      <div class="tab-content">
        <el-skeleton animated>
          <template slot="template">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-skeleton-item variant="h1" class="mb-1"/>
                <el-skeleton-item variant="h3" style="width: 20%; margin-right: 0.5rem" v-for="i in 3" :key="`small${i}`" animated class="mb-1"/>
                <el-skeleton-item variant="h3" style="width: 20%; margin-right: 0.5rem" v-for="i in 4" :key="`small${i}`" animated class="mb-1"/>
              </el-col>
              <el-col :span="8">
                <div class="d-flex gap-05 flex-justify-end">
                  <el-skeleton-item variant="button"  v-for="i in 3" :key="`small${i}`" animated class="mt-1 mb-1"/>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-skeleton>
        <el-skeleton animated class="mt-1">
          <template slot="template">
            <div class="d-flex gap-05 mt-1">
              <el-skeleton-item variant="rect" style="width: 15%; margin-right: 1rem" v-for="i in 5" :key="`small${i}`" animated class="mb-1"/>
            </div>
            <div class="d-flex gap-05">
              <el-skeleton-item variant="rect" style="width: 15%; margin-right: 1rem" v-for="i in 5" :key="`small${i}`" animated class="mb-1"/>
            </div>
            <div class="d-flex gap-05">
              <el-skeleton-item variant="rect" style="width: 50%; margin-right: 1rem" v-for="i in 2" :key="`small${i}`" animated class="mb-1"/>
            </div>
            <div class="d-flex gap-05">
              <el-skeleton-item variant="rect" style="width: 30%; margin-right: 1rem" v-for="i in 2" :key="`small${i}`" animated class="mb-1"/>
            </div>
            <div class="d-flex gap-05">
              <el-skeleton-item variant="rect" style="width: 40%; margin-right: 1rem" v-for="i in 2" :key="`small${i}`" animated class="mb-1"/>
            </div>
            <div class="d-flex gap-05">
              <el-skeleton-item variant="rect" style="width: 80%; margin-right: 1rem" v-for="i in 1" :key="`small${i}`" animated class="mb-1"/>
            </div>
          </template>
        </el-skeleton>
      </div>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
  .tab-skeleton-container {
    padding: 20px 0;
  }

  .tab-bar {
    border-bottom: 1px solid #E4E7ED;
    margin-bottom: 2rem;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .tab-content {
    // margin: 15px 2px;
    min-height: 80vh;
    // box-shadow: 0px 3px 6px rgb(233 233 233 / 50%);
    // border: 0.5px solid rgba(45, 58, 69, 0.15);
    border-radius: 3px;
    background-color: #FFFFFF;
    padding: 20px 25px;
  }
</style>
