<template>
  <!-- <div
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday
    }"
  > -->
  <div
    class="calendar-day"
    :class="{
      'calendar-no-regos': !hasRegos,
      'calendar-has-regos': hasRegos && day.isCurrentMonth,
      'calendar-day--not-current': !day.isCurrentMonth
    }"
    @click="clickDay(day.date)"
  >
    <span v-if="day.isCurrentMonth" :class="{ }">{{ label }}</span>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'CalendarMonthDayItem',

  props: {
    day: {
      type: Object,
      required: true
    },
    isCurrentMonth: {
      type: Boolean,
      default: false
    },
    isToday: {
      type: Boolean,
      default: false
    },
    hasRegos: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    label() {
      return dayjs(this.day.date).format('D');
    }
  },
  methods: {
    clickDay(date) {
      if (this.hasRegos) this.$emit('clickDay', date);
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-day {
  position: relative;
  line-height: 50px;
  font-size: clamp(1rem, 0.85vmax, 1.25rem);;
  background-color: #fff;
  color: 'black';
  padding: 5px;
  font-family: 'Century Gothic';
}

.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-day--not-current {
  background-color: #fff;
  color: #fff;
  pointer-events: none;
}

.calendar-day--today {
  padding-top: 4px;
}

.calendar-day--today > span {
  color: #fff;
  border-radius: 9999px;
}

.calendar-no-regos {
  color: lightgrey;
  pointer-events: none;
}
.calendar-has-regos {
  background-color: #F7F7F7;
  border: 1px solid white;
  color: #000;
}
</style>
