<template>
  <div
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isSelected
    }"
  >
    <div @click="selectDay(day.date)">
      <div class="week-label mt-2">
        {{ labelMonth }}
      </div>
      <div class="week-label label-day">
        {{ labelDay }}
      </div>
      <div
        :class="{
          'calendar-registration-count': isSelected,
          'calendar-registration-count--not-selected': !isSelected
        }"
        v-if="registration && registration.length"
      >
        {{ registration.length }}
      </div>
      <!-- <div v-for="reg in registration" :key="reg._id" class="calendar-rego-item" :class="selectedRegoId === reg._id ? 'calendar-selected-rego' : ''" @click="selectRego(reg)">
        {{ reg.name }}
        Duration: {{ reg.startDate | formatDate}} - {{ reg.endDate | formatDate}}
      </div> -->
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'WeekCalendarItems',
  props: {
    day: {
      type: Object,
      required: true
    },
    isCurrentMonth: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    registration: {
      type: Array,
      required: false
    },
    national_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedRegoId: undefined,
    };
  },
  methods: {
    selectDay(day) {
      console.log(day, 'select day');
      this.$emit('setNewWeekDays', day);
    },
    selectRego(rego) {
      if (this.selectedRegoId === rego._id) {
        this.selectedRegoId = undefined;
        this.$emit('setSelectedEntity', undefined);
      } else {
        this.selectedRegoId = rego._id;
        this.$emit('setSelectedEntity', rego._id);
      }
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD MMM YYYY');
    },
  },
  computed: {
    labelMonth() {
      return `${dayjs(this.day.date).format('ddd')}`;
    },
    labelDay() {
      return `${dayjs(this.day.date).format('D')}`;
    },
    link() {
      const portalUrlString = process.env[`VUE_APP_PORTAL_${this.national_id}_URL`];
      const portalUrl = _.get(process.env, portalUrlString) || process.env.VUE_APP_PORTAL_URL;
      console.log({
        portalUrlString,
        portalUrl,
      });
      return `${process.env.VUE_APP_PORTAL_URL}/finder?entityId=${this.selectedEntity}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-day {
  position: relative;
  background: #F7F7F7;
  font-size: 13px;
  color: 'black';
  padding: 5px;
  line-height: 20px;
  height: 8rem;
  margin: 0 auto;
  font-style: normal;
  border: 1px solid white;
  width: 95%;
  font-weight: bold;
  border-radius: 15px;
  font-family: 'BrownStd-Light';
}

.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.calendar-day--not-current {
  color: black;
}

.calendar-day--today {
  box-shadow: 0px 0px 4px 3px #4a4b4d;
}

.calendar-day--selected {
  padding-top: 4px;
}

.calendar-day--selected > div {
  cursor: pointer;
  background-color: $highlight;
  padding: 2px;
  color: $primary;
  border-radius: 1rem;
}

.calendar-rego-item:hover {
  cursor: pointer;
  background-color:black;
  color: #fff;
}

.calendar-selected-rego {
  background-color: black;
  color: #fff;
}

.calendar-registration-count {
  background-color: $highlight;
  // padding: 9px 14px 9px 14px;
  border-radius: 50%;
  width: 1.5rem;
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  color: #fff;
}

.calendar-registration-count--not-selected {
  background-color: $secondary;
  // padding: 9px 14px 9px 14px;
  border-radius: 50%;
  width: 1.5rem;
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  color: #fff;
}

.week-label {
  text-align: center;

  .label-day {
    font-weight: 800;
    font-size: 15px;
  }
}
</style>
