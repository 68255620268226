<template>
  <div class="mb-1" :class="{'calendar-container-mobile': isMobile}">
    <el-card>
      <CalendarMonth :selectedYear='selectedYear' :entityIds="entityIds" :entity="entity" :selectedMonth='selectedMonth' v-show="selectedMonth === undefined"  v-for="month in months" :key="month" :currMonth="month" @showWeek="showWeek" :http="http"/>
      <CalendarMonth :selectedYear='selectedYear' :entityIds="entityIds" :entity="entity" :selectedMonth='selectedMonth' v-show="selectedMonth !== undefined" @showWeek="showWeek" :http="http"/>
    </el-card>
  </div>
</template>

<script>
import CalendarMonth from './month';
import CalendarWeekdays from './weekdays';

export default {
  name: 'Calendar',
  props: ['selectedYear', 'selectedMonth', 'entityIds', 'entity', 'isMobile', 'http'],
  components: {
    CalendarMonth,
    // CalendarWeekdays
  },
  data() {
    return {
      months: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
      ]
    };
  },
  methods: {
    showWeek(weekDays) {
      this.$emit('weekData', weekDays);
    }
  },
};
</script>

<style>
  .calendar-container-mobile {
    /* margin: 0 20% 0 20%; */
  }
</style>
