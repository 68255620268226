<template>
  <div>
    <div class="day-of-week mt-1">
      <div v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>
    </div>
    <el-divider />
  </div>
</template>

<script>
const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export default {
  name: 'weekdays',

  computed: {
    weekdays() {
      return WEEKDAYS;
    }
  }
};
</script>

<style scoped>

.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  font-size:1em;
  text-align: center;
}
</style>
