import VueMq from 'vue-mq';
import VueDataTables from 'vue-data-tables';

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import * as VueGoogleMaps from 'vue2-google-maps';
import moment from 'moment';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import App from './App.vue';
import router from './router';
import store from './store/index';
import './plugins/element';
import './styles/_fa-override.scss';

Vue.use(VueMq, {
  breakpoints: {
    notSupported: 250,
    sm: 375,
    md: 768,
    lg: 1280,
  },
});
Vue.use(vueCustomElement);

Vue.config.productionTip = false;

Vue.prototype.$gmapApiPromiseLazy = () => new Promise((resolve) => resolve());
Vue.prototype.$gmapOptions = { autobindAllEvents: false };

// Vue Prototypes
Vue.prototype.moment = moment;

App.store = store;
App.router = router;
Vue.customElement('as-widget', App);
