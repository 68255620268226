<template>
  <div class="widget-outside-container" >
      <el-row class="app-container">
      <el-col :span="24" class="d-flex flex-column height-100-vh">
        <nav-bar class="nav-bar-container" v-if="shouldShowNavBar"/>
        <component :is="view" :widget_id="widget_id" :national_id="national_id"  v-show="!search.length"/>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    TableView: () => import('./components/TableView.vue'),
    TabView: () => import('./components/TabView.vue'),
    ReportView: () => import('./components/ReportView.vue'),
  },
  name: 'AppLayout',
  props: {
    widget_id: {
      type: Number,
    },
    national_id: {
      type: Number,
    },
  },
  mounted() {
    this._keyListener = (e) => {
      if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.handleCommand('switch-role');
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  },
  computed: {
    view() {
      return `${_.get(this.$route, 'meta.view', 'table')}-view`;
    },
    ...mapGetters('user', [
      'displayName',
      'abbrDisplayName',
      'activeRoleEntityName'
    ]),
    ...mapGetters('app', [
      'isSidebarOpened',
      'search',
      'searchPreference',
    ]),
    header() {
      return this.$store.state.app.headerName;
    },
    commVisible() {
      return this.$store.state.app.commVisible;
    },
    shouldShowNavBar() {
      // return true;
      // if route does not contain forms or communication
      return !(this.$route.path.includes('dashboard')) && !(this.$store.state.app.search.length) && !(this.$route.path === '/');
    }
  },
  data() {
    return {
      roleSwitcherModal: false,
      searchVisible: false,
      result: [],
    };
  },
  methods: {
    // goResult(colName, row) {
    //   if (colName === 'Users') return;
    //   this.$router.replace(`/${colName.toLowerCase()}/${row._id}`);
    //   this.search = '';
    // },
    handleCommand(command) {
      switch (command) {
        case 'account-details':
          break;
        case 'switch-role':
          this.roleSwitcherModal = true;
          break;
        case 'log-out':
          this.$store.commit('user/logout'); this.$router.replace('/login');
          break;
        default:
          break;
      }
    },
    // doSearch(search) {
    //   if (search.length >= 2) {
    //     this.$http.post('/assemble/api/v1/admin/dashboards/query', {
    //       query: search,
    //       preference: this.searchPreference
    //     }).then((res) => {
    //       this.result = res.data.result;
    //     });
    //   }
    // }
  },
  watch: {
    '$route.meta': {
      immediate: true,
      deep: true,
      // eslint-disable-next-line func-names
      handler(meta) {
        this.$store.commit('app/setHeaderName', meta.displayName);
      }
    },
  }
};
</script>

<style scoped lang="scss">
.widget-outside-container{
  // max-width: 1280px;
  margin: 0 auto;
}
.app-content-panel {
  // padding: 10px 20px;
  // border: 0.5px solid rgba(45, 58, 69, 0.3);
  // box-shadow: 0px 3px 6px rgba(233, 233, 233, 0.5);
  border-radius: 3px;
  background-color: #FFFFFF;
  // box-shadow: $boxShadow;
  // border: $border;
  border-radius: $borderRadius;
  &.hideSidebar {
    .app-container {
    }
  }
}

.switch-role-guide {
  display: flex;
  gap: 1rem;
}

.nav-bar-container {
  position: sticky;
  top: 0;
  z-index: 100;
}

.key-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  svg {
    max-width: 55%;
  }
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.position-setting{
  //set position to bottom left
  position: fixed;
  background: $highlight !important;
  bottom: 0;
  left: 0;
  width: $sideBarWidth;
  height: 5rem;
  box-shadow: 2px 0px 3px rgb(0 0 0 / 10%);
  padding: 1.5rem 1rem !important;
  // border-top: 1px solid $secondary;
  z-index: 2001;
  transition: all $sideBarTransitionTime ease-out;
  &:hover {
    filter: brightness(0.8);
    cursor: pointer;
  }
}

.user-setting-options {
		// text-align: right;
		// line-height: 40px;
		align-items: center;
    padding: 0 1rem 1rem 1rem;
		.el-avatar {
			margin-right: 1rem;
			// background-color: $highlight;
		}
    .displayName {
      font-size: 14px;
      font-weight: 500;
      color: $primary !important;;
    }
		.entity {
			font-size: 12px;
			line-height:12px;
			opacity: 0.6;
      color: $primary !important;
		}

	}
</style>
