// import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    sidebar: {
      closed: false,
    },
    commVisible: false,
    search: '',
    searchPreference: 'all',
    ignoreUnsupportedDevices: false,
    headerName: undefined
  },
  mutations: {
    toggleSidebar: (state) => state.sidebar.closed = !state.sidebar.closed,
    ignoreUnsupportedDevices: (state, payload) => state.ignoreUnsupportedDevices = payload || true,
    alwaysCloseSidebar: (state) => { if (!state.sidebar.closed) state.sidebar.closed = true; },
    setHeaderName: (state, payload) => state.headerName = payload,
    setSearch(state, payload) {
      state.search = payload;
    },
    setSearchPreference(state, payload) {
      state.searchPreference = payload;
    },
    setCommVisible(state, payload) {
      state.commVisible = payload;
    }
  },
  actions: {
  },
  getters: {
    isSidebarOpened: (state) => state.sidebar.closed,
    search: (state) => state.search,
    searchPreference: (state) => state.searchPreference,
  },
};
