<template>
  <div class="d-flex flex-column flex-justify-center unsupported-container">
      <img :src="nationalLogoAccount" width="70%" alt="Assemble Sports">
      <div class="mt-1 mb-1">
        <h3>Unsupported Mobile Devices</h3>
        <span class="description">This application does not support mobile devices at the moment.</span>
      </div>
      <el-button class="mt-3" type="primary" @click="$store.commit('app/ignoreUnsupportedDevices')">Ignore and Continue</el-button>
  </div>
</template>

<script>
export default {
  name: 'UnsupportedDevice',
  computed: {
    nationalLogoAccount() {
      // eslint-disable-next-line global-require
      return process.env.VUE_APP_LOGO_ACCOUNT && process.env.VUE_APP_LOGO_ACCOUNT !== '' ? process.env.VUE_APP_LOGO_ACCOUNT : require('@/assets/logos/Assemble_Logo_RGB_Positive.png');
    },
  }
};
</script>

<style lang="scss" scoped>
  .unsupported-container {
    border: 1px solid $offset;
    box-shadow: $boxShadow;
    border-radius: 4px;
    margin: 30px 15px;
    padding: 15px;
    align-items: center;
    h3 {
      text-align: center;
    }
    .description {
      font-size: 16px;
    }
  }
</style>
