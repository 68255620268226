<template>
  <div class="mb-1">
    <div class="week-table-container">
      <!-- <CalendarWeek /> -->
      <CalendarDateIndicator
        :selectedDate="selectedDate"
        class="calendar-month-header-selected-month mt-1 mb-1"
      />
      <div>
        <div class="days-grid">
          <i class="el-icon-arrow-left icon-lg arrow" @click="goPreviousDay"></i>
            <WeekCalendarItems
              v-for="(day, index) in days"
              :key="day.date"
              :day="day"
              :isSelected="day.isSelected"
              :registration="availableRegistrations[index]"
              :isMobile="isMobile"
              :national_id="national_id"
              @setNewWeekDays="setNewWeekDays"
              class="week-day"
            />
          <i class="el-icon-arrow-right icon-lg arrow" @click="goNextDay"></i>
          </div>
      </div>
    </div>
    <el-card class="mt-2">
      <el-table
      @row-click="setSelectedEntity"
      :data="selectedRego"
      style="width: 100%">
      <el-table-column
        prop="name" label="Name">
      </el-table-column>
      <el-table-column label="Venue">
        <template v-slot="scope">
          {{ get(scope.row, 'meta.address.formatted') }}
        </template>
      </el-table-column>
      <el-table-column label="Duration">
        <template v-slot="scope">
          {{ scope.row.startDate | formatDate}} - {{ scope.row.endDate | formatDate}}
        </template>
      </el-table-column>
    </el-table>
    </el-card>
    <div class="mt-2">
      <el-button
        class="mr-2"
        type="primary"
        icon="el-icon-back"
        @click="back"
        >Back</el-button>
      <!-- <a :href="link" target="_blank" v-if="selectedEntity"><el-button
        type="primary"
        icon="el-icon-right"
        >Select</el-button>
      </a> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import CalendarWeek from './weekdays';
import WeekCalendarItems from './weekItems';
import CalendarDateIndicator from './dateIndicator';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: 'Calendar',
  props: ['selectedYear', 'selectedMonth', 'days', 'availableRegistrations', 'selectedRego', 'isMobile', 'http', 'national_id'],
  components: {
    // CalendarWeek,
    WeekCalendarItems,
    CalendarDateIndicator
  },
  computed: {
    link() {
      return `${process.env.VUE_APP_PORTAL_URL}/finder?entityId=${this.selectedEntity}`;
    },
    selectedDate() {
      return this.days.length > 0 ? this.days.find((day) => day.isSelected) : moment();
    }
  },
  data() {
    return {
      get: _.get,
      selectedEntity: undefined,
    };
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD MMM YYYY');
    },
  },
  async created() {
    const nationalTimezone = await this.http.get('/assemble/api/v1/widgets/national/get/timezone');
    const timezone = nationalTimezone.data.data;
    moment.tz.setDefault(timezone);
  },
  methods: {
    goNextDay() {
      const currentDay = this.days.find((day) => day.isSelected);
      const newDay = dayjs(currentDay.date).add(1, 'day').format('YYYY-MM-DD');
      this.setNewWeekDays(newDay);
    },
    goPreviousDay() {
      const currentDay = this.days.find((day) => day.isSelected);
      const newDay = dayjs(currentDay.date).subtract(1, 'day').format('YYYY-MM-DD');
      this.setNewWeekDays(newDay);
    },
    setSelectedEntity(row) {
      this.selectedEntity = undefined;
      this.selectedEntity = row._id;
      const portalUrlString = `VUE_APP_PORTAL_${this.national_id}_URL`;
      const portalObject = _.get(process.env, portalUrlString);
      const portalUrl = portalObject || process.env.VUE_APP_PORTAL_URL;
      console.log({
        portalUrlString,
        portalUrl,
        portalObject,
      });
      window.open(`${portalUrl}/finder?entityId=${row._id}`, '_blank');
    },
    back() {
      this.$emit('back', true);
    },
    setNewWeekDays(date) {
      const day = this.getWeekday(date);
      const days = 6;
      const prev = day;
      const next = days - day;
      const weekDays = [];

      // for prev
      for (let i = prev; i >= 1; i--) {
        weekDays.push({ date: dayjs(date).subtract(i, 'day').format('YYYY-MM-DD'), isSelected: false });
      }
      // fo current
      weekDays.push({ date: dayjs(date).format('YYYY-MM-DD'), isSelected: true });
      // for next
      for (let i = 1; i <= next; i++) {
        weekDays.push({ date: dayjs(date).add(i, 'day').format('YYYY-MM-DD'), isSelected: false });
      }

      this.$emit('weekData', weekDays);
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
  },
};
</script>

<style lang="scss" scoped>
  .days-grid {
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: repeat(9, 1fr);
  }

  .week-day:hover {
    cursor: pointer;
    /* border-radius: 15px; */
  }

  .week-table-container {
    overflow-x: auto;
  }

  .arrow {
    cursor: pointer;
    font-size: 28px;
    text-align: center;
    margin: auto
  }
</style>
