<template>
  <el-row class="app-content f-grow-2">
    <el-col :span="24" class="app-content-panel">
      <tab-view-skeleton v-show="$store.state.rootLoading"/>
      <router-view v-loading="$store.state.rootLoading" :key="$route.fullPath" />
    </el-col>
  </el-row>
</template>
<script>
import TabViewSkeleton from './TabViewSkeleton.vue';

export default {
  name: 'TabView',
  components: {
    TabViewSkeleton
  },
};
</script>
<style scoped lang="scss">
</style>
