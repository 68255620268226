<template>
  <div :class="{'widget-container':true }" :style="getPresetDimension">
      <div  :class="{'widget-filter':true, 'filter-sticky':!isMobile, 'collapseInMobile': isMobile,'border-bottom':(!filter.selectedRound && !filter.selectedDate && !atTopPage && showFilter)}">
    <el-row :gutter="10">
     <el-col :span="isMobile|| isCustomMobile? 24:8" class="mt-2"  >
     <div >
       <el-select placeholder="Select Competiton" size="large"  value-key="_id" v-model="compId"  >
         <el-option
           v-for="competition in competitions"
           :key="competition._id"
           :label="competition.name"
           :value="competition._id"
         >
         </el-option>
       </el-select>
     </div>
    </el-col>
    <el-col :span="isMobile || isCustomMobile? 0: 8" class=""  >
      <div class="d-flex flex-column flex-justify-start flex-align-items-center text-center py-0">
        <span class="round">Round</span>
      <el-tabs :style="getRoundTabsWidth" type="card" v-model="selectedRoundBeforeHandle"  @tab-click="handleTabClick">
        <el-tab-pane v-for="(round,index) in getTotalRound" :key="index" :label="round" :name="round" >
        </el-tab-pane>
      </el-tabs>
      </div>
      </el-col>
      <el-col  :span="isMobile|| isCustomMobile? 24:8"  class="mt-2">
      <div class="d-flex flex-justify-space-between" >
      <div style="flex-basis: 90%;">
        <el-select placeholder="All Teams" size="large" collapse-tags  v-model="filter.selectedTeams" clearable>
        <el-option
          v-for="team in allTeams"
          :key="team._id"
          :label="team.name"
          :value="team._id"
        >        </el-option>
      </el-select>
      </div>
      <div class="ml-2" style="flex-basis: 10%; text-align: end; ">
        <el-popover
          placement="bottom-end"
          @show="popoverShow = true"
          @hide="popoverShow = false"
          trigger="click">
          <datepicker :monday-first="true" :clear-button="true"  wrapper-class="test" :bootstrap-styling="true"	 v-model="selectedDateBeforeHandle" @selected="handleDateSelected" :disabledDates="disabledDates" :inline="true">
          </datepicker>
          <el-button :type="popoverShow? 'primary': 'null'" size="medium" icon="el-icon-date" slot="reference" ></el-button>
        </el-popover>
      </div>
    </div>
      </el-col>
    </el-row>
    </div>
    <div class="widget-filter" v-show="isMobile || isCustomMobile">
      <el-row>
        <el-col :span="24" class="mt-3">
          <div class="d-flex flex-column flex-justify-center flex-align-items-center text-center">
            <span class="rounds-filter">Round</span>
            <el-tabs class="tabs" :style="getRoundTabsWidth" type="card" v-model="selectedRoundBeforeHandle"  @tab-click="handleTabClick">
              <el-tab-pane v-for="(round,index) in getTotalRound" :key="index" :label="round" :name="round" >
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <el-divider v-if="!filter.selectedRound && !filter.selectedDate && !atTopPage && showFilter "></el-divider> -->
    <div class="fixtures-container">
        <el-row>
        <!-- ipad and destop view -->
        <el-col v-show="!isMobile" :span="isMobile || isCustomMobile ? 0:24">
          <div class="mb-5" v-for="(fixtures, index) in currentFixturesFiltered" :key="index" >
          <h3 class="text-center d-flex flex-justify-center">{{dayFormatter(get(fixtures, '[0].dateTime'))}}</h3>
          <el-card shadow="never" class="box-card mt-2">
            <div slot="header" class="clearfix">
                <span class="text-center d-flex flex-justify-center competition-label" >{{fixtures[0].competition.name}}</span>
            </div>
            <div v-for="fixture in fixtures" :key="fixture._id" class="fixtures" :id="fixture._id" >
              <div class="time-venue d-flex flex-column flex-justify-center" >
                <div v-show="!fixture.meta.isBye" class="time  d-flex flex-align-items-center " :style="isDesktopSmall? `flex-direction:column; align-items:flex-start`: `` ">
                 <span class="mr-2"> {{ dateTimeFormatter(get(fixture, 'dateTime', 0) )}}</span>
                  <span class="round">{{ fixture.round.abbreviation || fixture.round.displayName }}</span>
                </div>
                <div v-show="!fixture.meta.isBye" v-if="widget.widgetSettings.showReferee" class="referee">
                  <span v-if="get(fixture, 'referees', []).length"> Ref:</span>
                  <span v-for="(referee, index) in get(fixture, 'referees', [])" :key="index">
                    {{referee.firstName}} {{referee.lastName}}
                    <span v-if="index < get(fixture, 'referees', []).length - 1">, </span>
                  </span>
                </div>
              </div>
              <!-- if isBye === false -->
              <div v-if="!fixture.meta.isBye" class="team flex-align-items-center">
                <span class="d-flex flex-justify-end flex-align-items-center">
                  <span class="team-name home mr-2">{{ getTeamFormatName(get(fixture, 'homeTeam._id'))}}</span>
                  <span v-if="widget.widgetSettings.showTeamCrest" class="team-logo ">
                    <img
                      :src="getTeamLogo(get(fixture, 'homeTeam._id', ''))"
                      style="object-fit: cover; width: 100%;"
                    />
                  </span>
                </span>
                <span class="team-scores flex-align-items-center d-flex flex-column flex-justify-end ">
                  <div class="d-flex flex-justify-center flex-align-items-end">
                    <span >
                      <span :class="{'f-bold': get(fixture, 'status') !== 'pre-game', 'op-05':get(fixture, 'status') === 'pre-game' }">{{get(fixture, 'status')=== 'pre-game'? '-' : get(fixture, 'scores.homeTeam', '0')}}</span>
                       <span class="op-05"> - </span>
                      <span :class="{'f-bold': get(fixture, 'status') !== 'pre-game', 'op-05':get(fixture, 'status') === 'pre-game' }">{{get(fixture, 'status')=== 'pre-game'? '-':get(fixture, 'scores.awayTeam', '0')}}</span>
                    </span>
                  </div>
                  <div>
                    <el-tag  effect="plain" type="info" size="small">
                      <span style="text-transform: uppercase;">{{(get(fixture, 'status', ''))}}</span>
                    </el-tag>
                  </div>
                </span>
                <div class="d-flex flex-justify-start flex-align-items-center">
                  <span v-if="widget.widgetSettings.showTeamCrest" class="team-logo mr-2">
                    <img
                      :src="getTeamLogo(get(fixture, 'awayTeam._id'))"
                      style="object-fit: cover; width: 100%;"
                    />
                  </span>
                  <span class="team-name away">{{ getTeamFormatName(get(fixture, 'awayTeam._id')) }}</span>
                </div>
              </div>
              <!-- if isBye === true -->
              <div v-else class="team-bye">
                <span>Round {{fixture.round.number}} Bye</span>
                <span v-if="widget.widgetSettings.showTeamCrest" class="team-logo">
                  <img
                  :src="getTeamLogo(get(fixture, 'homeTeam._id', ''))"
                  style="object-fit: cover; width: 60px; height: 60px;"
                  />
                </span>
                <span class="team-name ">{{ get(fixture, "homeTeam._id") ? getTeamFormatName(get(fixture, "homeTeam._id")) : getTeamFormatName(get(fixture, "awayTeam._id")) }}</span>
              </div>

              <div class="round-ref flex-justify-center">
                <div v-show="!fixture.meta.isBye" class="venue" v-if="widget.widgetSettings.showVenue">{{ get(fixture, 'venue.name', 'TBA') }}</div>
                <span v-show="!fixture.meta.isBye" class="venue" v-if="widget.widgetSettings.showVenue & widget.widgetSettings.showFields">{{get(fixture, 'meta.field.name', 'TBA')}}</span>
                <!-- <span class="ref">{{ sad }}</span> -->
              </div>
            </div>
          </el-card>
          </div>
        </el-col>
        <!-- mobile view -->
        <el-col v-show="isMobile" :span="isMobile || isCustomMobile? 24:0">
          <div class="scrollable">
            <div style="margin-bottom:80px" v-for="(fixtures, index) in currentFixturesFiltered" :key="index" >
            <h3 class="text-center d-flex flex-justify-center">{{dayFormatter(get(fixtures, '[0].dateTime'))}}</h3>
            <el-card shadow="never" v-for="fixture in fixtures" :key="fixture._id" class="box-card mt-3">
              <div slot="header" v-if="fixture._id === fixtures[0]._id" class="clearfix">
                  <span class="text-center d-flex flex-justify-center">{{fixtures[0].competition.name}}</span>
              </div>
              <div class="fixtures-mobile">
                <div v-if="!fixture.meta.isBye" class="team">
                  <div class="d-flex flex-column flex-align-items-center">
                    <span v-if="widget.widgetSettings.showTeamCrest" class="team-logo">
                      <img
                        :src="getTeamLogo(get(fixture, 'homeTeam._id', false))"
                        style="object-fit: cover; width: 100%;"
                      />
                    </span>
                      <span class="team-name home break-word">{{ getTeamFormatName(get(fixture, 'homeTeam._id'))}}</span>
                  </div>
                  <div>
                    <span class="team-scores">
                      <div class="match-status">
                        <el-tag effect="plain" type="info" size="small">
                          <span style="text-transform: uppercase;">{{(get(fixture, 'status', ''))}}</span>
                        </el-tag>
                      </div>
                      <div>
                        <span >
                        <span :class="{'f-bold': get(fixture, 'status') !== 'pre-game', 'op-05':get(fixture, 'status') === 'pre-game' }">{{get(fixture, 'status')=== 'pre-game'? '-' : get(fixture, 'scores.homeTeam', '0')}}</span>
                        <span class="op-05"> - </span>
                        <span :class="{'f-bold': get(fixture, 'status') !== 'pre-game', 'op-05':get(fixture, 'status') === 'pre-game' }">{{get(fixture, 'status')=== 'pre-game'? '-':get(fixture, 'scores.awayTeam', '0')}}</span>
                      </span>
                      </div>
                    </span>
                  </div>
                  <div class="d-flex flex-column flex-align-items-center">
                    <span v-if="widget.widgetSettings.showTeamCrest" class="team-logo">
                      <img
                        :src="getTeamLogo(get(fixture, 'awayTeam._id', false))"
                        style="object-fit: cover; width: 100%;"
                      />
                    </span>
                    <span class="break-word team-name away">{{ getTeamFormatName(get(fixture, 'awayTeam._id'))}}</span>
                  </div>

                </div>
                <div v-else class="team-bye">
                  <span>Round {{fixture.round.number}} Bye</span>
                  <span v-if="widget.widgetSettings.showTeamCrest" class="team-logo">
                    <img
                    :src="getTeamLogo(get(fixture, 'homeTeam._id', ''))"
                    style="object-fit: cover; width: 60px; height: 60px;"
                    />
                  </span>
                  <span class="team-name ">{{ get(fixture, "homeTeam._id") ? getTeamFormatName(get(fixture, "homeTeam._id")) : getTeamFormatName(get(fixture, "awayTeam._id")) }}</span>
                </div>
                <div class="time-venue" v-show="!fixture.meta.isBye">
                  <div class="time mb-10">{{ dateTimeFormatter(get(fixture, 'dateTime', 0) )}}
                  <span class="round">{{ fixture.round.abbreviation || fixture.round.displayName }}</span>
                  </div>
                  <div class="venue" v-if="widget.widgetSettings.showVenue">{{ get(fixture, 'venue.name', 'TBA') }}</div>
                  <span class="venue" v-if="widget.widgetSettings.showVenue">{{get(fixture, 'meta.field.name', 'TBA')}}</span>
                  <div class="ref" v-if="widget.widgetSettings.showReferee">
                    <span v-for="(referee, index) in get(fixture, 'referees', [])" :key="index">
                      Ref: {{referee.firstName}} {{referee.lastName}}
                      <span v-if="index < get(fixture, 'referees', []).length - 1">, </span>
                    </span>
                    </div>
                </div>
              </div>
            </el-card>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';

export default {
  components: {
    Datepicker
  },
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    http: {
      default: null
    },
    national_id: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      atTopPage: false,
      showFilter: false,
      popoverShow: false,
      currentRound: undefined,
      currentFixtures: undefined,
      regularSeasonRounds: undefined,
      get: _.get,
      capitilize: _.capitalize,
      date: moment().format('YYYY-MM-DD'),
      pickedDate: undefined,
      compId: undefined,
      selectedRoundBeforeHandle: undefined,
      selectedDateBeforeHandle: undefined,
      filter: {
        selectedTeams: undefined,
        selectedRound: undefined,
        selectedDate: undefined,
      },
      allTeams: [],
      isCustomMobile: false,
      // isMobile: false,
      maxWidth: 1280,
      widgetContainerWidth: 0,
    };
  },
  async created() {
    const nationalTimezone = await this.http.get('/assemble/api/v1/widgets/national/get/timezone');
    const timezone = nationalTimezone.data.data;
    moment.tz.setDefault(timezone);
  },
  mounted() {
    document.documentElement.style.setProperty('--primary-color', process.env[`VUE_APP_${this.national_id}_PRIMARY_COLOR`]);
    document.documentElement.style.setProperty('--secondary-color', process.env[`VUE_APP_${this.national_id}_SECONDARY_COLOR`]);
    document.documentElement.style.setProperty('--highlight-color', process.env[`VUE_APP_${this.national_id}_HIGHLIGHT_COLOR`]);
    if (this.widget.widgetSettings.competitionList.length) {
      this.compId = this.widget.widgetSettings.competitionList[0]._id;
    }
    const dayArray = document.getElementsByClassName('day-header');
    for (let i = 0; i < dayArray.length; i++) {
      dayArray[i].innerText = dayArray[i].innerText.substring(0, 1);
    }
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        this.atTopPage = true;
        this.showFilter = false;
      } else {
        this.atTopPage = false;
        this.showFilter = true;
      }
    };
    // evnet listener for window resize
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      setTimeout(this.handleResize, 300); // Adjust timeout as necessary
    });
  },
  computed: {
    isDesktopSmall() {
      if (this.widget.widgetSettings.hasCustomWidth && this.widget.widgetSettings.customWidth > 600 && this.widget.widgetSettings.customWidth < 900) {
        return true;
      }
      if (this.widgetContainerWidth > 600 && this.widgetContainerWidth < 900) {
        return true;
      }

      return false;
    },
    clubPlaceholder() {
      return process.env[`VUE_APP_${this.national_id}_LOGO_ACCOUNT`];
    },
    competitions() {
      return _.get(this, 'widget.widgetSettings.competitionList', []);
    },
    disabledDates() {
      const currentFixtures = _.get(this, 'currentFixtures', []);
      const matchDay = [];
      // get all dates from fixtures that have matches
      const dates = _.map(currentFixtures, (fixtures) => moment(_.get(fixtures, '[0].dateTime', 0)).format('YYYY-MM-DD'));
      // format needed for datepicker
      // Sun Oct 16 2022 00:00:00 GMT+1100 (Australian Eastern Standard Time)
      _.forEach(dates, (date) => {
        matchDay.push(moment(date).format('YYYY-MM-DD'));
      });
      // return array that exludes matchday
      const customPredictor = (date) => {
        const dateFormated = moment(date).format('YYYY-MM-DD');
        return !_.includes(matchDay, dateFormated);
      };

      return {
        customPredictor,
      };
    },
    getTotalRound() {
      const numFinalRounds = this.currentFixtures
        .flat()
        .map((r) => r.round).filter((r) => r.type.includes('Final')).map((r) => r.number)
        .filter((round, index, self) => self.indexOf(round) === index).length;
      const allRound = this.regularSeasonRounds + numFinalRounds;
      // turn all round into array of strings
      const roundArray = _.map(_.range(1, allRound + 1), (round) => `${round}`);
      return roundArray;
    },
    currentFixturesFiltered() {
      console.log('currentFixturesFiltered');
      let fixturesList = _.cloneDeep(_.get(this, 'currentFixtures', []));
      let fixturesListNext = _.cloneDeep(_.get(this, 'currentFixtures', []));
      if (this.filter.selectedTeams) {
        fixturesList = _.map(fixturesList, (fixtures) => _.filter(fixtures, (fixture) => this.filter.selectedTeams === _.get(fixture, 'homeTeam._id') || this.filter.selectedTeams === _.get(fixture, 'awayTeam._id')))
          .filter((fixtures) => fixtures.length);
      }
      if (this.filter.selectedRound) {
        const selectedRound = parseInt(this.filter.selectedRound);
        console.log('selectedRound', selectedRound);
        console.log('fixturesList', fixturesList);

        console.log('totalRound', this.getTotalRound);
        // finals handling
        if (selectedRound > this.regularSeasonRounds) {
          fixturesList = _.map(fixturesList, (fixtures) => _.filter(fixtures, (fixture) => fixture.round.type.includes('Final') && fixture.round.number === selectedRound - this.regularSeasonRounds)).filter((fixtures) => fixtures.length);
        } else {
          console.log('semi');
          fixturesList = _.map(fixturesList, (fixtures) => _.filter(fixtures, (fixture) => _.get(fixture, 'round.number') === selectedRound && _.get(fixture, 'round.type').includes('Regular'))).filter((fixtures) => fixtures.length);
        }
        const isComplete = _.every(fixturesList, (fixture) => _.every(fixture, (fx) => fx.status === 'final'));
        console.log('isComplete', isComplete);
        if (isComplete) {
          fixturesListNext = _.map(fixturesListNext, (fixtures) => _.filter(fixtures, (fixture) => _.get(fixture, 'round.number') === selectedRound + 1 && _.get(fixture, 'round.type').includes('Regular'))).filter((fixtures) => fixtures.length);
          fixturesList = [...fixturesList, ...fixturesListNext];
        }
        console.log('fixturesListAfterFiltered', fixturesList);
      }
      if (this.filter.selectedDate) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.filter.selectedDate = _.cloneDeep(moment(this.filter.selectedDate).format('YYYY-MM-DD'));
        fixturesList = _.map(fixturesList, (fixtures) => _.filter(fixtures, (fixture) => moment(_.get(fixture, 'dateTime', 0)).format('YYYY-MM-DD') === this.filter.selectedDate)).filter((fixtures) => fixtures.length);
      }
      return fixturesList;
    },
    getRoundTabsWidth() {
      return 'width: 75%';
    },
    getPresetDimension() {
      if (this.widget.widgetSettings.hasCustomHeight) {
        if (this.widget.widgetSettings.hasCustomWidth) {
          if (this.widget.widgetSettings.customWidth < 600) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.isCustomMobile = true;
          }
          return `max-width:${this.widget.widgetSettings.customWidth}px; 
          height:${this.widget.widgetSettings.customHeight}px; 
          overflow-y:auto;
          overflow-x:hidden;
          `;
        }
        return ` height:${this.widget.widgetSettings.customHeight}px; 
        overflow-y:auto;
        overflow-x:hidden;
        `;
      }
      if (_.get(this.widget, 'widgetSettings.hasCustomWidth') && !this.widget.widgetSettings.hasCustomHeight) {
        if (this.widget.widgetSettings.customWidth < 600) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isCustomMobile = true;
        }
        return `max-width:${this.widget.widgetSettings.customWidth}px;`;
      }
      return '';
    },
    isMobile() {
      return this.widgetContainerWidth <= 600;
    }

  },
  methods: {
    getTeamFormatName(teamId) {
      const foundTeam = _.find(this.allTeams, (team) => team._id === teamId);
      const teamName = _.get(foundTeam, 'name', '');
      const teamShortName = _.get(foundTeam, 'shortName', '');
      const teamAbbreviation = _.get(foundTeam, 'abbreviation', '');
      if (foundTeam) {
        if (this.widget.widgetSettings.teamNameFormat === 'teamShortName') {
          return teamShortName || teamName;
        } if (this.widget.widgetSettings.teamNameFormat === 'teamAbbreviation') {
          return teamAbbreviation || teamShortName || teamName;
        }
        return teamName;
      }
      return '';
    },
    handleResize() {
      // if (window.innerWidth < 400) {
      //   this.isMobile = true;
      // } else {
      //   this.isMobile = false;
      // }
      // get width of the  class widget-container
      const widgetContainer = document.querySelector('.widget-container');
      if (widgetContainer) {
        const widgetContainerWidth = widgetContainer.offsetWidth;
        this.widgetContainerWidth = widgetContainerWidth;
        console.log('widgetContainerWidth', this.widgetContainerWidth);
        // if (widgetContainerWidth <= 600) {
        //   this.isMobile = true;
        // } else if (widgetContainerWidth > 650) {
        //   this.isMobile = false;
        // }
      }
    },
    handleScroll() {
      // set isScrolling to true and then back to false after 1000ms
      const currentScroll = window.scrollY;

      this.isScrolling = true;
      setTimeout(() => {
        this.isScrolling = false;
      }, 1000);

      // if (this.isScrolling) {
      //   this.isScrolling = false;
      // }
      if (window.scrollY > 0) {
        this.atTopPage = false;
      } else {
        this.atTopPage = true;
      }
    },
    checkStartOnCurrentDate() {
      const fixtureList = _.cloneDeep(_.get(this, 'currentFixtures', []));
      // find the first fixture that has status of 'pre-game'. If none exists, find the round after the latest final. If none exists, show last round
      let currentFixturesRound;
      const preGameRound = _.find(fixtureList, (fixtures) => _.find(fixtures, (fixture) => fixture.status === 'pre-game'));
      const finalRound = _.find(fixtureList, (fixtures) => _.find(fixtures, (fixture) => fixture.status === 'final'));
      const roundAfterFinal = finalRound ? _.find(fixtureList, (fixtures) => fixtures[0].round.number === finalRound[0].round.number + 1) : null;
      if (preGameRound) {
        currentFixturesRound = preGameRound;
      } else if (roundAfterFinal) {
        currentFixturesRound = roundAfterFinal;
      } else {
        currentFixturesRound = _.last(fixtureList);
      }

      // scroll to the first fixture
      if (currentFixturesRound) {
        const currentRound = _.get(currentFixturesRound, '0.round.number');
        this.currentRound = currentRound;
        this.filter.selectedRound = currentRound;
        const firstFixture = _.find(currentFixturesRound, (fixture) => _.get(fixture, 'status') === 'pre-game');
        if (firstFixture) {
          const { _id } = firstFixture;
          // get id after next tick
          this.$nextTick(() => {
            const element = document.getElementById(_id);
            const headerOffset = 200;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset;
            setTimeout(() => {
              const tab = document.getElementById(`tab-${currentRound}`);
              const tabList = document.getElementsByClassName('el-tabs__nav is-top');
              const tabListArray = Array.prototype.slice.call(tabList);
              tabListArray.forEach((t) => {
                t.addEventListener('mouseover', () => {
                // add hover text
                  // eslint-disable-next-line no-param-reassign
                  t.title = 'Click to filter by round';
                });
              });

              _.assign(tab.style, {
                border: `1px solid ${process.env.VUE_APP_HIGHLIGHT_COLOR}`,
                // backgroundColor: '#f5f5f5',
                // add border to the tab
              });
              // add hover effect to the tab
              tab.addEventListener('mouseover', () => {
                // add hover text
                tab.title = 'Current Round';
              });
            }, 100);

            setTimeout(() => {
              window.scrollTo({
                top: offsetPosition,
                behavior: 'auto',
              });
            }, 100);
          });
        }
      }
    },
    handleTabClick(tab) {
      if (!this.filter.selectedRound) {
        console.log('tab1', tab);
        this.filter.selectedRound = tab.name;
        this.filter.selectedDate = undefined;
        this.selectedDateBeforeHandle = undefined;
      } else if (this.filter.selectedRound === tab.name) {
        console.log('tab2', tab);
        this.filter.selectedRound = undefined;
        this.selectedRoundBeforeHandle = undefined;
        this.selectedDateBeforeHandle = undefined;
      } else {
        console.log('tab3', tab);
        this.filter.selectedRound = tab.name;
        this.filter.selectedDate = undefined;
      }
    },
    handleDateSelected(date) {
      const formattedDate = _.cloneDeep(moment(this.filter.selectedDate).format('YYYY-MM-DD'));
      if (!this.filter.selectedDate) {
        this.filter.selectedDate = date;
        this.selectedRoundBeforeHandle = undefined;
        this.filter.selectedRound = undefined;
      } else if (this.filter.selectedDate === formattedDate) {
        this.filter.selectedDate = undefined;
        this.selectedRoundBeforeHandle = undefined;
        this.selectedDateBeforeHandle = undefined;
      } else {
        this.filter.selectedDate = date;
        this.filter.selectedRound = undefined;
      }
    },

    daysFormatter(day) {
      switch (day) {
        case 'Sun':
          return 'S';
        case 'Mon':
          return 'M';
        case 'Tue':
          return 'T';
        case 'Wed':
          return 'W';
        case 'Thu':
          return 'T';
        case 'Fri':
          return 'F';
        case 'Sat':
          return 'S';
        default:
          return day;
      }
    },
    getBreakpoint() {
      return `width: ${this.breakpoint}`;
    },
    getTeamLogo(team) {
      if (team) {
        const foundTeam = _.find(this.allTeams, { _id: team });
        if (foundTeam) {
          return _.get(foundTeam, 'meta.imgUrl', this.clubPlaceholder);
        }
        return this.clubPlaceholder;
      }
      return this.clubPlaceholder;
    },
    resetFilter() {
      this.filter.selectedTeams = undefined;
      this.filter.selectedRound = undefined;
      this.filter.selectedDate = undefined;
    },
    handleCurrentRoundChange(val) {
      this.filter.selectedRound = val;
    },
    dateTimeFormatter(dateTime) {
      // return time in format HH:MM AM/PM
      return this.widget.widgetSettings.timeOption === '12hrs'
        ? moment(dateTime).format('h:mm a')
        : moment(dateTime).format('HH:mm');
    },
    dayFormatter(dateTime) {
      // return day in format Weekday, MMM DD YYYY
      if (this.widget.widgetSettings.showDate) {
        if (this.widget.widgetSettings.showDay) {
          return moment(dateTime).format('dddd, MMM DD YYYY');
        }
        return moment(dateTime).format('MMM DD YYYY');
      }

      return '';
    },
    // refactorFixtures() {
    //   this.currentFixtures = _.groupBy(this.currentFixtures, (fixture) => moment(fixture.dateTime).format('YYYY-MM-DD'));
    //   this.currentFixtures = _.toArray(this.currentFixtures);
    // }

  },
  watch: {
    compId: {
      async handler(val) {
        if (val) {
        // get preview ladder
          // reset filter
          this.resetFilter();
          this.currentFixtures = [];
          try {
            this.$store.commit('loading', true);
            const resFixtures = await this.http.get(`/assemble/api/v1/widget/matches/competition/${val}`);
            this.currentFixtures = resFixtures.data.data;
            this.allTeams = resFixtures.data.teams;
            this.regularSeasonRounds = resFixtures.data.regularSeasonRounds;
            this.currentFixtures = _.groupBy(this.currentFixtures, (fixture) => moment(fixture.dateTime).format('YYYY-MM-DD'));
            this.currentFixtures = _.toArray(this.currentFixtures);
            // add id to the class of each fixture

            this.checkStartOnCurrentDate();
            this.key++;
            this.$store.commit('loading', false);
          } catch (error) {
            this.$store.commit('loading', false);
            this.$message.error(error.response.data.message);
          }
        }
      },
      immediate: true,

    },
  },

};
</script>

<style lang="scss" scoped>
::v-deep.el-select .el-input__inner, .el-input .el-input__inner, .el-input-number .el-input__inner{
  border-radius: 10px;
  // border: solid 1px #fff;
}
::v-deep.el-select .el-input.is-focus .el-input__inner{
  background: var(--highlight-color);
  color: #fff;
}
::v-deep .el-card__body{
  padding: 0;
}
::v-deep .el-card__header{
  background: var(--highlight-color);
  color: #fff;
}

::v-deep .vdp-datepicker__calendar {
  border: none !important;
  font-size: 17px;
  // font-weight: normal;
  // font-stretch: normal;
  font-style: normal;
  // line-height: 2.35;
  // letter-spacing: normal;
  color: #333;
  // background-color: #eee;

  .cell{
    background-color: #eee;
    // margin: 0 0 1px;
    border: solid 1px #ccc;
  }
  .blank{
    opacity: 0.2; ;
    background-color: #eee;
  }
  .selected{
    background-color: var(--highlight-color) !important;
    color:#fff;
    border: solid 1px var(--highlight-color) !important;
  }
  .day-header{
      color: #333;
  opacity: 0.5;
  }
}
::v-deep .vdp-datepicker__calendar .cell.day-header {
  border: none !important;
  background-color: #fff;
}

::v-deep .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year{
  border: solid 1px #ccc;
  background-color: #fff;
}
::v-deep .vdp-datepicker__calendar header span{
 background: #fff;
}
::v-deep .vdp-datepicker__calendar header .prev:after {
  border-right: 6px solid #000;
  margin-left: -5px;
}
::v-deep .vdp-datepicker__calendar header .next:after {
  border-left: 6px solid #000;
  margin-left: 5px;
}
::v-deep .el-button{
  text-align: center;
  padding: 9px 12px 9px 12px;
  border-radius: 10px;
  font-size: 18px;
  color: #606266;
  // border: solid 1px var(--highlight-color);
  // background-color: white;
}
::v-deep .el-button--primary{
  text-align: center;
  padding: 9px 12px 9px 12px;
  border-radius: 10px;
  border: solid 1px var(--highlight-color);
  background-color: var(--highlight-color);
  color: #fff;
}

::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
  // width:32px;
  // height:40px;
  background: var(--highlight-color);
  border-radius: 100px;
  border: none !important;
  color: #fff !important;
  padding: 0 13px
}

::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
  border: none;
}
// ::v-deep.el-tabs--card > .el-tabs__header{
//   width:280px;
// }
::v-deep.el-tabs--card>.el-tabs__header {
  border: none;
  margin : 0;
  display: flex;
  justify-content: center;
  .el-tabs__item{
    border-radius: 100px;
    line-height:32px;
    border: unset;
    padding: 0 13px !important;
    margin: 0 5px;
    font-size:15px;
    // width: 32px;
    height: 32px;
  }
}
::v-deep.el-tabs:not(.report-view) > .el-tabs__content > .el-tab-pane{
  padding: 0;
}
 .el-tabs__nav-scroll{
  display: flex;
  max-width: 37vw;
  // justify-content: center;
 }
::v-deep .el-tabs__nav-next {
  top:-5px;
  font-size: 16px;
  // padding-left: 20px;
  // color: #000;
}
::v-deep .el-tabs__nav-prev  {
  top:-5px;
  font-size: 16px;
  margin-right: 20px;
  // check if disabled
}
::v-deep .el-icon-arrow-right:before {
  content: '\e791';
}
::v-deep .el-icon-arrow-left:before {
  content: '\e792';
}
::v-deep .el-icon-arrow-up:before {
  content: '\e78f';
}
::v-deep .el-icon-arrow-down:before {
  content: '\e790';
}

.widget-container{
  padding:0 20px;
  // max-width: 1280px;

  .widget-filter{
    // max-width: 1280px;
    margin: 0 auto;
    min-height:110px;
    position: -webkit-sticky;
    position: sticky;
    top:0;
    z-index: 100;
    background: #fff;

    // margin-bottom: 2.5rem;

    .tabs{
      width: 70vw;
    }
    .rounds-filter{
      padding: 1rem 0 0.5rem 0;
    }
  }

  .fixtures-container{
    // max-width: 1280px;
    margin: 0 auto;
    .box-card {
    border-radius: 10px;
    .clearfix{
      color:#fff;
      opacity:0.6
    }

    .fixtures{
      display: grid;
      grid-template-columns: 1.7fr 6.5fr 1.7fr;
      min-height: 103px;
      border-top: solid 1px #ddd;
      padding: 8px 24px 12px 24px;
      .time-venue{
      .time{
        font-size: 16px;
        font-weight: bold;
        color: #000;
        .round{
          // padding-left: 18px;
          font-size: 1rem;
          color: #152934;
          opacity: 0.5;
        }
      }
      .referee{
        font-size: 1rem;
        color: #152934;
        opacity: 0.5;
        padding-top: 5px;
      }
      .venue{
        color: #152934;
        opacity: 0.5;
      }
    }
      .team{
        display: grid;
        grid-template-columns: 4fr 2fr 4fr;
        height: 100%;
        .team-logo{
          width: 24px;
          height: 24px;
          // margin: 0 35px 9px;
          transition: all 0.1s ease-in-out;
        }

        .team-name{
          font-size: clamp(0.8rem, 1.2vw, 1rem);
          height: 100%;
          word-break: break-word;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
        }
        .home{
          text-align: end;
          display: flex;
          justify-content: flex-end;
          padding-top:6px;
        }
        .away{
          text-align: start;
          display: flex;
          justify-content: flex-start;
          padding-top:6px;
        }
        .team-scores{
          font-size: 22px;
          text-align: center;
          height: 100%;
          min-width:150px;
          padding: 0 20px;
        }
      }
      .team-bye{
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:10px;
        .team-logo{
          // width: 24px;
          // height: 24px;
          // margin: 0 35px 9px;
          transition: all 0.1s ease-in-out;
        }
      }
      .round-ref{
        display:flex;
        flex-direction: column;
        align-content: flex-end;
        align-items: flex-end;
        font-size: 18px;
        color: #152934;
        opacity: 0.5;
        .venue{
          font-size: 1rem;
      }
      }
    }
    .fixtures-mobile{
      display: flex;
      flex-direction: column;
      padding: 13px 15px 20px;
      .time-venue{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-top: 20px ;
        .ref{
          font-size: 1rem;
          color: #152934;
          opacity: 0.5;
          padding-top: 5px;
        }
      .time{
        font-size: 1.2rem;
        font-weight: bold;
        color: #000;
        .round{
          padding-left: 10px;
          font-size: 1.2rem;
          color: #152934;
          opacity: 0.5;
        }
      }
      .venue{
        font-size: 14px;
        color: #152934;
        opacity: 0.5;
      }
    }
      .team{
        display: grid;
        grid-template-columns: 2fr 2fr 2fr;
        height: 100%;
        padding-bottom: 20px;
        border-bottom: rgba($color: #152934, $alpha: 0.2) 1px solid;
        .team-name{
          font-size: clamp(0.8rem, 1.2vw, 1.1rem);
          font-weight: normal;
          text-align: center;
          height: 100%;
          margin: 9px 0 0;
        }
        .team-logo{
          width: 24px;
          height: 24px;
          // margin: 0 35px 9px;

          transition: all 0.1s ease-in-out;
        }
        .team-scores{
          font-size: 24px;
          text-align: center;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 10px;
          .match-status{
            display: flex;
            align-content: flex-start;
            justify-content: center;
          }
        }
      }
      .team-bye{
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:10px;
        .team-logo{
          // width: 24px;
          // height: 24px;
          // margin: 0 35px 9px;
          transition: all 0.1s ease-in-out;
        }
      }
      .round-ref{
        display:flex;
        justify-content: flex-end;
        font-size: 18px;
        color: #152934;
        opacity: 0.5;
      }
    }
}
  }
  .scrollable {
    height: 500px;
    overflow: overlay;
  }
}

</style>
<style scoped>
.el-divider{
  margin: 0 0 0 0;
  padding: 0 10px 0 10px;
  width: 100vw;
  height: 1px;
  background-color: #ddd;
  position: sticky;
  top: 100px;
  z-index: 100;

}
.filter-sticky{
  padding-top:30px;
}
.collapseInMobile{
  position: unset !important;
}
.border-bottom{
  border-bottom: 1px solid #ddd;
}

.is-disabled{
  /* pointer-events: none; */
  /* cursor: not-allowed; */
  /* color: black !important; */
}
.input-group{
  text-align: end;
  font-size: 25px;

}
.el-tabs__nav-scroll{
  display: flex;
  max-width: 60vw;
  /* justify-content: center; */
 }
/* .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2){
  padding: 0 13px !important;
} */

</style>
