<template>
  <el-row :class="{ 'app-content': true, 'f-grow-2': true, 'full': isDashboard }">
    <el-col :span="24" class="app-content-panel">
      <router-view v-loading="$store.state.rootLoading" :widget_id="widget_id" :national_id="national_id" />
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    widget_id: {
      type: Number,
    },
    national_id: {
      type: Number,
    },
  },
  mounted() {
  },
  computed: {
    isDashboard() {
      return this.$route.path === '/' || this.$route.path === '/dashboard';
    }
  }

};
</script>
<style scoped lang="scss">
.app-content-panel{
  // padding: 10px 20px;
  // border: 0.5px solid rgba(45, 58, 69, 0.3);
  // box-shadow: 0px 3px 6px rgba(233, 233, 233, 0.5);
  border-radius: 3px;
  background-color: #FFFFFF;
  // box-shadow: $boxShadow;
  // border: $border;
  border-radius: $borderRadius;
}

.full {
  padding: 0 !important;
  .app-content-panel {
    // padding: 20px !important;
  }
}
</style>
