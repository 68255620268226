<template>
  <div class="layout-container">
    <!-- Content -->
    <div class="content">
      <router-view  />
    </div>
    <!-- Footer -->
    <div class="d-flex">
      <div class="footer w-100 d-flex flex-column flex-justify-center flex-align-items-center flex-0" >
        <div>Copyright &#169; 2022 <a href="https://www.assemblesports.com/" target="_blank">Assemble Sports</a></div>
        <div>All rights reserved.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.footer {
  color: $secondary;
  font-size: 0.8em;
  padding: 2em;
  height: 7em;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/logos/assemble-poweredby-logo.svg');
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: left center;

}
</style>
