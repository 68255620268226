<template>
  <div class="app">
    <!-- <unsupported-browser v-if="isIE" /> -->
    <unsupported-device v-if="isMobile && !$store.state.app.ignoreUnsupportedDevices" />
    <component v-else  :is="layout" :widget_id="widget_id" :national_id="national_id" ></component>
    <!-- <as-widget></as-widget> -->
  </div>
</template>
<script>
import UnsupportedDevice from './components/UnsupportedDevice.vue';
import UnsupportedBrowser from './components/UnsupportedBrowser.vue';

export default {
  components: {
    UnsupportedDevice,
    UnsupportedBrowser,
    AppLayout: () => import('./layouts/AppLayout.vue'),
    LoginLayout: () => import('./layouts/LoginLayout.vue'),
  },
  props: ['widget_id', 'national_id'],
  computed: {
    layout() {
      return `${(this.$route.meta.layout || 'login')}-layout`;
    },
    isMobile() {
      // https://stackoverflow.com/a/11381730
      // return (window.innerWidth <= 600 || window.innerHeight <= 800);
      // return if window width is less than 250px
      // return window.clientWidth < 250;
      return !['md', 'lg', 'sm'].includes(this.$mq);
    },
    isIE() {
      const ua = navigator.userAgent;
      /* MSIE used to detect old browsers and Trident used to newer ones */
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: $fontRegular;
  color: $primary;
}
.app {
  height: 100%;
}
h1 {
  font-family: $fontRegular;
  // color: $primary;
}
</style>
