<template>
  <div class="map-container">
    <GmapMap
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      :options="mapOptions"
      @click="handleMapClick"
    >
      <GmapMarker
        v-for="marker in markers"
        :key="marker.id"
        :position="marker.position"
        :clickable="true"
        :draggable="marker.draggable"
        @click="handleMarkerClick(marker)"
      />
    </GmapMap>
  </div>
</template>

<script>
import { Map, Marker } from 'vue2-google-maps';

export default {
  name: 'GoogleMap',
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Object,
      default: () => ({ lat: -33.8688, lng: 151.2093 }),
    },
    initialZoom: {
      type: Number,
      default: 11,
    },
  },
  components: {
    GmapMap: Map,
    GmapMarker: Marker,
  },
  data() {
    return {
      zoom: this.initialZoom,
      mapOptions: {
        mapTypeId: 'roadmap',
      },
    };
  },
  computed: {
    // google: gmapApi,
  },
  mounted() {
    this.$nextTick(() => {
      this.fitBounds();
    });
  },
  watch: {
    markers: {
      handler() {
        this.$nextTick(() => {
          this.fitBounds();
        });
      },
      deep: true,
    },
    center: {
      handler(newCenter) {
        if (this.$refs.mapRef) {
          this.$refs.mapRef.$mapObject.setCenter(newCenter);
          this.$refs.mapRef.$mapObject.setZoom(this.initialZoom);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleMapClick(event) {
      this.$emit('map-click', event);
    },
    handleMarkerClick(marker) {
      this.$emit('marker-click', marker);
    },
    fitBounds() {
      if (!this.$refs.mapRef || this.markers.length === 0) return;

      const bounds = new window.google.maps.LatLngBounds();
      this.markers.forEach((marker) => bounds.extend(marker.position));
      this.$refs.mapRef.$mapObject.fitBounds(bounds);
    },
    // fitBounds() {
    //   if (!this.google || !this.$refs.mapRef || this.markers.length === 0) return;

    //   const bounds = new this.google.maps.LatLngBounds();
    //   this.markers.forEach((marker) => bounds.extend(marker.position));
    //   this.$refs.mapRef.$mapObject.fitBounds(bounds);
    // },
  },
};
</script>

<style scoped>
.vue-map-container {
  width: 100%;
  height: 500px !important; /* Set a fixed height or use a percentage */
}
</style>
