<template>
  <div>
    <div class="day-of-week">
      <div v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>
    </div>
    <!-- <el-divider /> -->
  </div>
</template>

<script>
const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export default {
  name: 'weekdays',

  computed: {
    weekdays() {
      return WEEKDAYS;
    }
  }
};
</script>

<style lang="scss" scoped>
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 14%);
}

.day-of-week > * {
  text-align: center;
}
</style>
