<template>
  <div class="calendar-month">
    <!-- <div class="calendar-month-header">
      <CalendarDateSelector
        :current-date="today"
        :selected-date="selectedDate"
        @dateSelected="selectDate"
      />
    </div> -->
    <!-- <CalendarWeekdays/> -->
    <CalendarDateIndicator
      :current-date="today"
      :selected-date="selectedDate"
      :selectedMonth="selectedMonth"
      :month="currMonth"
      @dateSelected="selectDate"
      v-if="selectedDate"
      class="calendar-month-header-selected-month mt-1"
    />
    <div class="days-grid mt-1">
      <CalendarMonthDayItem
        v-for="day in days"
        :key="day.date"
        :day="day"
        :hasRegos="hasRegos(day)"
        class="month-day"
        @clickDay="weekday"
        :is-today="day.date === today"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _, { times } from 'lodash';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import CalendarMonthDayItem from './monthDays';
import CalendarDateIndicator from './dateIndicator';
import CalendarDateSelector from './dateSelector';
import CalendarWeekdays from './weekdays';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: 'CalendarMonth',
  props: ['selectedYear', 'selectedMonth', 'currMonth', 'entity', 'entityIds', 'http'],

  components: {
    CalendarMonthDayItem,
    CalendarDateIndicator,
    // CalendarDateSelector,
    // CalendarWeekdays
  },

  data() {
    return {
      rows: [],
      selectedDate: dayjs(`${this.selectedYear}`).month(this.currMonth || this.selectedMonth || 0),
      availableRegistrations: [],
    };
  },
  async mounted() {
    this.availableRegistrations = await this.fetchEntity();
  },
  computed: {
    watchProps() {
      return `${this.currentMonthDays}|${this.entityIds}|${this.entity}|${this.selectedYear}|${this.selectedMonth}|${this.currMonth}`;
    },
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays
      ];
    },
    today() {
      return dayjs().format('YYYY-MM-DD');
    },
    month() {
      return Number(this.selectedDate.format('M'));
    },
    year() {
      return Number(this.selectedDate.format('YYYY'));
    },
    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },
    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => ({
        date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(
          'YYYY-MM-DD'
        ),
        isCurrentMonth: true
      }));
    },
    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date
      );
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        'month'
      );

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday || 7;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
        .date();

      const prevMonthDays = [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => ({
          date: dayjs(
            `${previousMonth.year()}-${previousMonth.month()
                + 1}-${previousMonthLastMondayDayOfMonth + index}`
          ).format('YYYY-MM-DD'),
          isCurrentMonth: false
        })
      );

      if (prevMonthDays.length !== 7) return prevMonthDays;

      return [];
    },

    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`
      );

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month');

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 6 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => ({
        date: dayjs(
          `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
        ).format('YYYY-MM-DD'),
        isCurrentMonth: false
      }));
    }
  },
  methods: {
    hasRegos(day) {
      const timeStamp = dayjs(day.date).valueOf();

      const hasRegistrations = this.availableRegistrations.some((rego) => rego.startDate <= timeStamp && rego.endDate >= timeStamp && _.get(rego, 'isActive', false) === true);
      // console.log({
      //   day,
      //   hasRegistrations,
      // });
      return hasRegistrations;
    },
    weekday(date) {
      const day = this.getWeekday(date);
      const days = 6;
      const prev = day;
      const next = days - day;
      const weekDays = [];

      // for prev
      for (let i = prev; i >= 1; i--) {
        weekDays.push({ date: dayjs(date).subtract(i, 'day').format('YYYY-MM-DD'), isSelected: false });
      }
      // fo current
      weekDays.push({ date: dayjs(date).format('YYYY-MM-DD'), isSelected: true });
      // for next
      for (let i = 1; i <= next; i++) {
        weekDays.push({ date: dayjs(date).add(i, 'day').format('YYYY-MM-DD'), isSelected: false });
      }

      this.$emit('showWeek', weekDays);
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },
    async fetchEntity() {
      const res = await this.http.post('/assemble/api/v1/widget/entity', {
        entity: this.entity,
        entityIds: this.entityIds,
      });

      console.log({
        fetchEntityRes: res.data.data,
      });

      return res.data.data;
    }
  },
  watch: {
    selectedYear(year) {
      this.selectedDate = dayjs(`${year}`).month(this.currMonth || this.selectedMonth);
    },
    selectedMonth(month) {
      if (month) this.selectedDate = dayjs(`${this.selectedYear}`).month(month);
      else this.selectedDate = dayjs(`${this.selectedYear}`).month(this.currMonth);
    },
    currMonth(month) {
      this.availableRegistrations = [];
      if (month) this.selectedDate = dayjs(`${this.selectedYear}`).month(month);
    },
    async watchProps() {
      if (this.entityIds.length && this.entity) {
        // this.availableRegistrations = [];
        const daysInMonth = dayjs(this.selectedDate).daysInMonth();
        const selectedDates = [];
        for (let i = 1; i < daysInMonth; i++) {
          selectedDates.push(dayjs(this.selectedDate).set('date', i).valueOf());
        }

        this.$store.commit('loading', true);

        const result = await this.fetchEntity();
        this.availableRegistrations = result;

        this.$store.commit('loading', false);
      } else {
        this.availableRegistrations = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-month {
  position: relative;
}

.day-of-week {
  font-size: 18px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
}

.month-day:hover {
  background-color: $secondary;
  color: #FFF;
  cursor: pointer;
  // border-radius: 15px;
}

.days-grid {
  position: relative;
}
</style>
