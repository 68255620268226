<template>
  <div>
    <fixtures-widget v-if="widgetType === 'fixture' && loaded" :widget="widget" :http="http" :national_id="national_id" ></fixtures-widget>
    <ladder-widget v-if="loaded && widgetType === 'ladderboard'" :widget="widget" :http="http" :national_id="national_id" ></ladder-widget>
    <calendar-widget v-if="widget.widgetType === 'calendar'"  :widget="widget" :http="http" :national_id="national_id" :season="calendarSeason"/>
    <club-finder-widget v-if="widget.widgetType === 'clubFinder'"  :widget="widget" :http="http" :national_id="national_id" :searchTitle="widget.searchTitle" />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import fixturesWidget from './components/fixturesWidget.vue';
import ladderWidget from './components/ladderWidget.vue';
import calendarWidget from './components/calendarWidget.vue';
import clubFinderWidget from './components/ClubFinderWidget.vue';

export default {
  components: {
    fixturesWidget,
    ladderWidget,
    calendarWidget,
    clubFinderWidget
  },
  props: {
    widget_id: {
      type: Number,
    },
    national_id: {
      type: Number,
    },
  },
  data() {
    return {
      widget: {},
      widgetType: '',
      loaded: false,
    };
  },
  async beforeMount() {
    try {
      this.$store.commit('loading', true);
      const resWidget = await this.http.get(`/assemble/api/v1/widget/${this.widget_id}`);
      this.widget = resWidget.data.data;
      // console.log({
      //   widget: this.widget,
      // });
      this.loaded = true;
      this.$store.commit('loading', false);
      if (this.widget && this.widget.widgetType) {
        this.widgetType = this.widget.widgetType;
      }
    } catch (error) {
      this.$store.commit('loading', false);
      this.$store.commit('error', error);
    }
  },
  computed: {
    calendarSeason() {
      const currentSeason = new Date().getFullYear();
      return _.get(this.widget, 'widgetSettings.calendarEntity.season', currentSeason);
    },
    http() {
      // get dynamic url from national_id at process.env.VUE_APP_NATIONAL_${this.nationa_id}_URL
      const apiUrl = process.env[`VUE_APP_NATIONAL_${this.national_id}_URL`];
      // switch (this.national_id) {
      //   case 1: apiUrl = process.env.VUE_APP_NATIONAL_1_URL; break;
      //   case 2: apiUrl = process.env.VUE_APP_NATIONAL_2_URL; break;
      //   case 3: apiUrl = process.env.VUE_APP_NATIONAL_3_URL; break;
      //   case 4: apiUrl = process.env.VUE_APP_NATIONAL_4_URL; break;
      //   case 5: apiUrl = process.env.VUE_APP_NATIONAL_5_URL; break;
      //   case 6: apiUrl = process.env.VUE_APP_NATIONAL_6_URL; break;
      //   case 7: apiUrl = process.env.VUE_APP_NATIONAL_7_URL; break;
      //   case 8: apiUrl = process.env.VUE_APP_NATIONAL_8_URL; break;
      //   case 9: apiUrl = process.env.VUE_APP_NATIONAL_9_URL; break;
      //   default: break;
      // }
      const http = axios.create({
        baseURL: `${apiUrl}`,
        headers: {
          'Content-Type': 'application/json',
          'national-id': this.national_id,
        },
      });
      return http;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
