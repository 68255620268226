import Vue from 'vue';
import VueRouter from 'vue-router';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import store from '../store/index';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0);
//   if (to.matched.some((record) => _.get(record, 'meta.requiresAuth') !== false)) {
//     const now = moment().valueOf();
//     const expiry = store.getters['user/expiry'];
//     const userId = store.getters['user/userId'];
//     const isAuthenticated = store.getters['user/isAuthenticated'];
//     const refreshToken = store.getters['user/refreshToken'];
//     const verified = store.getters['user/isVerified'];
//     const roles = store.getters['user/roles'];

//     if (isAuthenticated && expiry - 300 > now) {
//       // Valid Token
//       if (!verified && to.path !== '/verify') {
//         // Account not verified, redirect to verify page
//         next('/verify');
//       } else if (roles.length <= 0 && to.name !== 'pending') {
//         // Account verified, but no roles
//         next('/pending');
//       } else {
//         // Account Verified
//         next();
//       }
//     } else if (expiry - 300 <= now && refreshToken && userId) {
//       // Expired Token, refresh token before going to page
//       store.commit('loading', true);
//       httpNoAuth.post('/assemble/api/v1/account/admin/authorize',
//         {
//           refresh_token: refreshToken,
//           user_id: userId,
//         })
//         .then((response) => {
//           // Refresh Successful - Login User
//           const profile = jwtDecode(response.data.id_token);
//           store.commit('user/login', { profile, tokens: response.data });
//           store.commit('loading', false);
//           next();
//         })
//         .catch(() => {
//           // Failed Refresh - Logout User
//           store.commit('user/logout');
//           store.commit('loading', false);

//           next('/login');
//         });
//     } else {
//       // No Data - Redirect to Login
//       store.commit('user/logout');
//       store.commit('loading', false);
//       next('/login');
//     }
//   } else {
//     next();
//   }
// });

export default router;
