<template>
  <div>
    <div style="margin: 0 auto;width:100%;display:flex;flex-direction:column;flex:1 0 auto;padding:15px;">
        <div style="flex-direction:column;align-items:center;text-align:center;margin: 0 auto;display:flex;max-width:500px;width:100%;">
            <div style="border-radius:4px;border:1px solid #EBEEF5;background-color:#FFFFFF;overflow:hidden;color:#0D0D0D;transition:0.3s;margin-left:auto;margin-right:auto;max-width:100%;text-align:center;margin-top:20px;width:100%;">
                <div style="padding:20px;">
                    <img :src="nationalLogoAccount"
                         style="width:70%;">
                    <h3>Browser not supported</h3>
                    <p>Unfortunately We does not support Internet Explorer. Some critical security features are
                        not available for your browser version.</p>
                    <p>We recommend using the latest versions of Chrome, Firefox or Edge to access the admin.</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnsupportedBrowser',
  computed: {
    nationalLogoAccount() {
      // eslint-disable-next-line global-require
      return process.env.VUE_APP_LOGO_ACCOUNT && process.env.VUE_APP_LOGO_ACCOUNT !== '' ? process.env.VUE_APP_LOGO_ACCOUNT : require('@/assets/logos/Assemble_Logo_RGB_Positive.png');
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
