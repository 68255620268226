<template>
  <div>
    <year @weekData="weekData" v-show="showYearCalendar" :selectedYear='year' :selectedMonth='selectedMonth' :isMobile='isMobile' :entityIds="entityIds" :http="http" :entity="entity"/>
    <week :national_id="national_id" @weekData="weekData" v-show="weekDays.length && entityIds.length" :days="weekDays" :availableRegistrations="availableRegistrations" :selectedRego="selectedRego" :http="http" @back="back"/>
  </div>
</template>

<script>
import moment from 'moment';
import Year from './year-table/index';
import Week from './week-table';

export default {
  props: ['selectedYear', 'selectedMonth', 'entityIds', 'entity', 'isMobile', 'http', 'national_id'],
  components: {
    Year,
    Week
  },
  data() {
    return {
      showYearCalendar: true,
      weekDays: [],
      availableRegistrations: [],
      selectedRego: []
    };
  },
  computed: {
    year() {
      return this.selectedYear;
    }
  },
  methods: {
    back(bool) {
      if (bool === true) {
        this.weekDays = [];
        this.showYearCalendar = bool;
      }
      this.$emit('setWeekView', false);
    },
    weekData(weekDays) {
      this.weekDays = weekDays;
    }
  },
  watch: {
    async weekDays(val) {
      if (val.length > 0 && this.entityIds.length > 0) {
        // current selected date
        const currSelected = val.find((c) => c.isSelected);
        this.showYearCalendar = false;
        const selectedDates = val.map((d) => moment(d.date).valueOf());

        this.$store.commit('loading', true);

        const res = await this.http.post('/assemble/api/v1/widget/registration', {
          entity: this.entity,
          entityIds: this.entityIds,
          selectedDates,
          currSelected
        });

        this.availableRegistrations = res.data.data;
        this.selectedRego = res.data.selected;
        this.$emit('setWeekView', true);

        this.$store.commit('loading', false);
      } else if (!this.entityIds.length) {
        this.$message.error('Please select entity first');
        this.$emit('setWeekView', false);
      }
    },
  }
};
</script>

<style>
</style>
