// account routes

module.exports = [
  {
    path: '/widgets/create',
    name: 'widgets.create',
    meta: {
      displayName: 'New Widget',
      layout: 'app',
      requiresAuth: true,
    },
    component: () => import('../views/widgets/widgetDetails.vue'),
  },
  {
    path: '/widget/:id',
    name: 'widgets.details',
    meta: {
      displayName: 'Widget Details',
      layout: 'app',
      requiresAuth: true,
    },
    component: () => import('../views/widgets/widgetDetails.vue'),
  },
  {
    path: '*',
    meta: {
      displayName: 'Dashboard',
      layout: 'app',
      requiresAuth: true,
    },
    component: () => import('../views/widgets/widgetDetails.vue'),
  },
];
