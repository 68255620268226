<template>
  <div class="calendar-date-indicator">
    <div v-if="selectedMonth">
      <div class="date-indicator-control">
        <div>
          <div class="date">{{ currentYear }}</div>
        </div>
        <div>
          <i class="el-icon-arrow-left icon-lg" @click="selectPrevious"></i>
          <div class="date">{{ currentMonth }}</div>
          <i class="el-icon-arrow-right icon-lg" @click="selectNext"></i>
        </div>
      </div>
    </div>
    <div v-else>
      {{ currentMonth }} {{ currentYear }}
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: ['selectedDate', 'currentDate', 'selectedMonth', 'month'],
  computed: {
    currentMonth() {
      return this.month ? this.selectedDate.month(this.month).format('MMMM') : this.selectedDate.format('MMMM');
    },
    currentYear() {
      return this.month ? this.selectedDate.month(this.month).format('YYYY') : this.selectedDate.format('YYYY');
    }
  },

  methods: {
    selectPrevious() {
      const newSelectedDate = dayjs(this.selectedDate).subtract(1, 'month');
      this.$emit('dateSelected', newSelectedDate);
    },

    selectCurrent() {
      const newSelectedDate = dayjs(this.currentDate);
      this.$emit('dateSelected', newSelectedDate);
    },

    selectNext() {
      const newSelectedDate = dayjs(this.selectedDate).add(1, 'month');
      this.$emit('dateSelected', newSelectedDate);
    }
  }
};
</script>

<style scoped>
.calendar-date-indicator {
  font-weight: 1000;
  font-size: clamp(1rem, 0.85vmax, 1.25rem);
  text-align: center;
  font-family: 'BrownStd-Light';
}

.date {
  font-size: 20px;
  width: 8rem;
  display: inline-block;
}

.calendar-date-indicator > * {
  cursor: pointer;
  user-select: none;
}

.icon-lg {
  font-size: 20px;
}

.date-indicator-control {
  display: flex;
  justify-content: space-evenly;
}
</style>
