<template>
  <div class="calendar-date-indicator">{{ month }}</div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    month() {
      return dayjs(this.selectedDate.date).format('MMMM') || dayjs().format('MMMM');
    }
  }
};
</script>

<style scoped>
.calendar-date-indicator {
  font-weight: 1000;
  font-size: clamp(1rem, 0.85vmax, 1.25rem);;
  text-align: center;
  font-family: 'BrownStd-Light';
}
</style>
