<template>
  <div class="club-details-popup" v-if="club">
    <div class="club-details-popup-header">
      <img :src="get(club, 'meta.imgUrl', false) || nationalLogo" :alt="club.name">
      <h2>{{ club.name }}</h2>
    </div>
    <p><strong>Association:</strong> {{ get(club, 'orgtree.association.name', '') }}</p>
    <p><strong>State:</strong> {{ get(club, 'orgtree.state.name', '') }}</p>
    <p><strong>National:</strong> {{ get(club, 'orgtree.national.name', '') }}</p>
    <p><strong>Contact:</strong> {{ get(club, 'contact.name', '') }}</p>
    <p><strong>Phone:</strong> {{ get(club, 'contact.number', '') }}</p>
    <p><strong>Email:</strong> {{ get(club, 'contact.email', '') }}</p>
    <p><strong>Venue:</strong> {{ get(club, 'meta.venue.name', '') }}</p>
    <p><strong>Secondary Venue:</strong> {{ get(club, 'meta.secondaryVenue.name', '') }}</p>

    <el-button type="primary" @click="goRegister" v-if="club.meta && club.meta.slug">Register</el-button>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'ClubDetailsPopup',
  props: {
    club: Object,
    national_id: String
  },
  data() {
    return {
      get,
    };
  },
  methods: {
    goRegister() {
      const portalUrlString = process.env[`VUE_APP_PORTAL_${this.national_id}_URL`];
      const portalUrl = get(process.env, portalUrlString) || process.env.VUE_APP_PORTAL_URL;
      const { club } = this;
      if (club.meta && club.meta.slug) {
        const url = `${portalUrl}/c/club/${club.meta.slug}/finder`;
        window.open(url, '_blank');
      }
    }
  },
  computed: {
    nationalLogo() {
      const logo = process.env[`VUE_APP_NATIONAL_${this.national_id}_LOGO`];
      return logo || require('@/assets/logos/Assemble_Logo_RGB_Positive.png');
    }
  }
};
</script>

<style lang="scss" scoped>
.club-details-popup {
  padding: 2rem;
  .club-details-popup-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    // justify-content: center;
    img {
      max-width: 5rem;
      max-height: 5rem;
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
}
</style>
