<template>
  <div class="club-finder-widget" :style="getPreviewStyle">
    <!-- <h1 class="widget-title">
      {{ widget.name || '' }}
    </h1> -->
    <SearchBar :title="widget.searchTitle || 'Find your place to get involved'" @search="handleSearch" class="mb-1" />
    <div class="widget-content mb-1" v-if="isFullPageView">
      <div class="left-panel" :class="{ 'full-width': isMobileView }">
        <Filters @filter="handleFilter" :participantTypes="participantTypes" :genders="genders" :ages="ages" :competitionTypes="competitionTypes" />
        <ResultsList :clubs="filteredClubs" @selectClub="handleClubSelect" :national_id="national_id" class="mt-1"/>
      </div>
      <Map v-if="!isMobileView" :clubs="filteredClubs" :searchLocation="searchLocation" @selectClub="handleClubSelect" :http="http" :national_id="national_id" />
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      :with-header="false"
      :size="isMobileView ? '100%' : '450px'"
      direction="rtl"
    >
      <ClubDetailsPopup :club="selectedClub" :national_id="national_id" v-if="selectedClub" />
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import SearchBar from './club-finder/SearchBar.vue';
import Filters from './club-finder/Filters.vue';
import ResultsList from './club-finder/ResultsList.vue';
import Map from './club-finder/Map.vue';
import ClubDetailsPopup from './club-finder/ClubDetailsPopup.vue';

function loadGoogleMapsScript(apiKey) {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      resolve(window.google.maps);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve(window.google.maps);
    script.onerror = reject;

    document.head.appendChild(script);
  });
}

const mockClubs = [
  {
    _id: 1,
    name: 'Eastside Rugby Club',
    orgtree: {
      association: {
        _id: 'assoc123',
        name: 'Eastern Rugby Association'
      },
      state: {
        _id: 'state123',
        name: 'New South Wales',
        type: 'State'
      },
      national: {
        _id: 'nat123',
        name: 'Rugby Australia'
      }
    },
    contact: {
      name: 'John Smith',
      number: '(02) 1234 5678',
      email: 'info@eastsiderugby.com'
    },
    isActive: true,
    module: {
      competitions: true,
      events: true,
      memberships: true,
      additionalQs: false,
      saleableItems: true
    },
    meta: {
      venue: {
        _id: 'venue123',
        name: 'Eastside Rugby Ground',
        venueTimezone: 'Australia/Sydney',
        lat: -33.8688,
        lng: 151.2093
      },
      secondaryVenue: {
        _id: 'venue124',
        name: 'Eastside Training Field',
        venueTimezone: 'Australia/Sydney',
        lat: -33.8688,
        lng: 151.2093
      },
      primaryColor: '#FF0000',
      secondaryColor: '#FFFFFF',
      abn: '12 345 678 901',
      imgUrl: 'https://assemble-uat.s3.amazonaws.com/logos/full-size/300074.png?v=1640309117545?v=1726539777092',
      slug: 'eastside-rugby-club',
      fromEntityRego: false,
      useCustomDateCutoff: false,
      customDateCutoffMonth: null,
      customDateCutoffDay: null,
      displayEntitiesBelow: true,
      activeKid: {
        clientId: 'kid123',
        clientSecret: 'secret123',
        businessId: 'business123',
        activity: 'Rugby'
      },
    },
    suburb: 'Eastside',
    postcode: '2010',
    country: 'Australia',
    distance: 1.5
  },
  {
    _id: 2,
    name: 'Westside Football Club',
    orgtree: {
      association: {
        _id: 'assoc456',
        name: 'Western Football Association'
      },
      state: {
        _id: 'state456',
        name: 'New South Wales',
        type: 'State'
      },
      national: {
        _id: 'nat456',
        name: 'Football Australia'
      }
    },
    contact: {
      name: 'Jane Doe',
      number: '(02) 9876 5432',
      email: 'info@westsidefc.com'
    },
    isActive: true,
    module: {
      competitions: true,
      events: true,
      memberships: true,
      additionalQs: true,
      saleableItems: false
    },
    meta: {
      venue: {
        _id: 'venue456',
        name: 'Westside Stadium',
        venueTimezone: 'Australia/Sydney',
        lat: -33.8688,
        lng: 151.2093
      },
      secondaryVenue: {
        _id: 'venue457',
        name: 'Westside Indoor Arena',
        venueTimezone: 'Australia/Sydney',
        lat: -33.8688,
        lng: 151.2093
      },
      primaryColor: '#0000FF',
      secondaryColor: '#FFFF00',
      abn: '98 765 432 109',
      imgUrl: 'https://assemble-uat.s3.amazonaws.com/logos/full-size/300074.png?v=1640309117545?v=1726539777092',
      slug: 'westside-football-club',
      fromEntityRego: true,
      useCustomDateCutoff: true,
      customDateCutoffMonth: 6,
      customDateCutoffDay: 30,
      displayEntitiesBelow: false,
      activeKid: {
        clientId: 'kid456',
        clientSecret: 'secret456',
        businessId: 'business456',
        activity: 'Football'
      }
    },
    suburb: 'Westside',
    postcode: '2011',
    country: 'Australia',
    distance: 2.0
  }
];

export default {
  name: 'ClubFinderWidget',
  components: {
    SearchBar,
    Filters,
    ResultsList,
    Map,
    ClubDetailsPopup
  },
  props: ['breakpoint', 'widget', 'http', 'national_id', 'searchTitle'],
  data() {
    return {
      isFullPageView: false,
      //   clubs: [],
      filteredClubs: mockClubs,
      selectedClub: null,
      searchLocation: null,
      clubs: mockClubs,
      drawerVisible: false,
      participantTypes: [],
      genders: [],
      ages: [],
      competitionTypes: [],
      clientDimensions: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      }
    };
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.getWidgetFilterOptions();
    await this.initGoogleMaps();
  },
  beforeDestroy() {
    // Clean up listener
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.clientDimensions = {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      };
    },
    async initGoogleMaps() {
      try {
        const apiKey = 'AIzaSyAPIJ-9AuUJDulNAPUZCYqXdjpVavxoj8g'; // Make sure to set this in your .env file
        const googleMaps = await loadGoogleMapsScript(apiKey);
        console.log('Google Maps loaded successfully');
      } catch (error) {
        console.error('Failed to load Google Maps:', error);
      }
    },
    async getWidgetFilterOptions() {
      const response = await this.http.post('/assemble/api/v1/widget/club-finder-filters');
      const {
        participantTypes, genders, ages, competitionTypes
      } = response.data.data;
      console.log({
        participantTypes, genders, ages, competitionTypes
      });
      this.participantTypes = participantTypes;
      this.genders = genders;
      this.ages = ages;
      this.competitionTypes = competitionTypes;
    },
    handleSearch(location) {
      this.searchLocation = location;
      this.isFullPageView = true;
      this.fetchClubs(location);
    },
    handleFilter(filters) {
      // Apply filters to clubs
      this.applyFilters(filters);
    },
    handleClubSelect(club) {
      this.selectedClub = club;
      this.drawerVisible = true;
    },
    async fetchClubs(location, filters = {}) {
      try {
        const response = await this.http.post(`/assemble/api/v1/widget/club-finder/${location}`, {
          filters: {
            participantType: filters.participantType,
            gender: filters.gender,
            age: filters.age,
          }
        });
        this.clubs = response.data.data;
        const { widget } = this;
        console.log({ widgetSettings: widget.widgetSettings });
        if (_.get(widget, 'widgetSettings.clubExcludeList', []).length) {
          const excludeIds = widget.widgetSettings.clubExcludeList.map((club) => club._id);
          console.log({ excludeIds });
          this.filteredClubs = this.clubs.filter((club) => !excludeIds.includes(club._id));
        } else {
          this.filteredClubs = this.clubs;
        }
      } catch (error) {
        console.error('Error fetching clubs:', error);
        this.$message.error('Failed to fetch clubs. Please try again.');
      }
    },
    applyFilters(filters) {
      this.fetchClubs(this.searchLocation, filters);
    },
  },
  computed: {
    clientWidth() {
      return this.clientDimensions.width;
    },
    clientHeight() {
      return this.clientDimensions.height;
    },
    isMobileView() {
      const { widgetSettings } = this.widget;
      if (widgetSettings.hasCustomHeight || widgetSettings.hasCustomWidth) {
        if (widgetSettings.hasCustomWidth && this.clientWidth <= widgetSettings.customWidth) return true;
        if (widgetSettings.hasCustomHeight && this.clientHeight <= widgetSettings.customHeight) return true;
      }
      return this.breakpoint === 'mobile' || this.breakpoint === 'mobile-custom';
    },
    getPreviewStyle() {
      const { widgetSettings } = this.widget;
      let style = '';

      if (widgetSettings.hasCustomHeight) {
        const height = `${widgetSettings.customHeight}px`;
        style = `max-height:${height}; overflow-y:auto;`;

        if (widgetSettings.hasCustomWidth) {
          style += ` max-width:${widgetSettings.customWidth}px;`;
        }
      } else if (widgetSettings.hasCustomWidth) {
        style = `max-width:${widgetSettings.customWidth}px; height:800px; overflow-y:auto;`;
      }

      return style;
    }
  },
};
</script>

<style scoped lang="scss">
.club-finder-widget {
  max-width: 1680px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;

  .widget-content {
    display: flex;
    gap: 20px;
  }

  .left-panel {
    width: 45%;
    &.full-width {
      width: 100%;
    }
  }

  .map {
    width: 55%;
    height: 600px;
    border-radius: 8px;
    overflow: hidden;
  }

  @media (max-width: 1200px) {
    .left-panel {
      width: 45%;
      &.full-width {
        width: 100%;
      }
    }

    .map {
      width: 55%;
    }
  }

  @media (max-width: 992px) {
    .widget-content {
      flex-direction: column;
    }

    .left-panel,
    .map {
      width: 100%;
    }

    .map {
      height: 500px;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;

    .map {
      height: 400px;
    }
  }

  @media (max-width: 576px) {
    .map {
      height: 300px;
    }
  }
}

.filters-container {
  .filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}

.results-list {
  .club-item {
    .club-main {
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

.club-details-popup {
  .club-details-popup-header {
    @media (max-width: 576px) {
      flex-direction: column;
      text-align: center;

      img {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
