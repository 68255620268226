<template>
  <div class="filters-container">
    <div class="filters">
      <div class="filter-item">
        <label for="participantType">Participant Type</label>
        <el-select id="participantType" v-model="participantType" placeholder="Participant Type" clearable>
          <el-option value="all" label="All Participants"></el-option>
          <el-option v-for="type in participantTypes" :key="type.value" :value="type.value" :label="type.label"></el-option>
        </el-select>
      </div>
      <div class="filter-item">
        <label for="gender">Gender</label>
        <el-select id="gender" v-model="gender" placeholder="Gender" clearable>
          <el-option value="All" label="All Genders"></el-option>
          <el-option value="Female" label="Female"></el-option>
          <el-option value="Male" label="Male"></el-option>
          <el-option value="Other" label="Other"></el-option>
        </el-select>
      </div>
      <div class="filter-item">
        <label for="age">Age</label>
        <el-select id="age" v-model="age" placeholder="Age" clearable @clear="age = 'all'">
          <el-option value="all" label="All Ages"></el-option>
          <el-option v-for="age in ages" :key="age.value" :value="age.value" :label="age.label"></el-option>
        </el-select>
      </div>
    </div>
    <el-button class="mt-1" type="primary" @click="applyFilters">Apply Filters</el-button>
  </div>
</template>
<script>
export default {
  name: 'Filters',
  props: {
    participantTypes: {
      type: Array,
      required: true
    },
    genders: {
      type: Array,
      required: true
    },
    ages: {
      type: Array,
      required: true
    },
    competitionTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      participantType: 'all',
      gender: 'All',
      age: 'all',
      competitionType: 'all'
    };
  },
  async mounted() {
    await this.getWidgetFilterOptions();
  },
  methods: {
    applyFilters() {
      this.$emit('filter', {
        participantType: this.participantType,
        gender: this.gender,
        age: this.age,
        competitionType: this.competitionType
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filter-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-weight: bold;
  }
}
</style>
