<template>
  <div class="calendar-date-selector">
    <el-button
      class="mt-2"
      type="primary"
      icon="el-icon-arrow-left"
      size="small"
      @click="selectPrevious"
      circle
      ></el-button>
    <el-button
      class="mt-2"
      type="primary"
      icon="el-icon-arrow-right"
      size="small"
      @click="selectNext"
      circle
      ></el-button>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'CalendarModeSelector',

  props: {
    currentDate: {
      type: String,
      required: true
    },

    selectedDate: {
      type: Object,
      required: true
    }
  },

  methods: {
    selectPrevious() {
      const newSelectedDate = dayjs(this.selectedDate).subtract(1, 'month');
      this.$emit('dateSelected', newSelectedDate);
    },

    selectCurrent() {
      const newSelectedDate = dayjs(this.currentDate);
      this.$emit('dateSelected', newSelectedDate);
    },

    selectNext() {
      const newSelectedDate = dayjs(this.selectedDate).add(1, 'month');
      this.$emit('dateSelected', newSelectedDate);
    }
  }
};
</script>

<style scoped>
.calendar-date-selector {
  display: flex;
  justify-content: space-between;
  width: 80px;
}

.calendar-date-selector > * {
  cursor: pointer;
  user-select: none;
}
</style>
