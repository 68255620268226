<template>
  <div class="app-content f-grow-2">
    <router-view v-loading="$store.state.rootLoading" />
  </div>
</template>
<script>
export default {

};
</script>
<style scoped lang="scss">
</style>
