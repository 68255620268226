<template>
  <div :class="{'widget-container':true}" :style="getPresetDimension">
    <div class="widget-filter" >
    <div class="entity-container mb-1">
      <el-row :gutter="15">
      <el-col :span="isMobile || isCustomMobile ? 24: 12" class="mt-2"  >
        <div >
          <el-select placeholder="Select Entity" size="large"  value-key="_id" v-model="entityId" multiple>
            <el-option v-show="entityIds.length > 1" :value="0" label="All">All</el-option>
            <el-option
              v-for="entity in entityIds"
              :key="entity._id"
              :label="entity.name"
              :value="{_id: entity._id, entity: entity.entity }"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col v-show="!isWeekView" :span="24">
        <CalendarWeekdays />
      </el-col>
    </el-row>
    <div class="calendar-container">
      <el-row>
        <el-col :span="24">
          <calendar :national_id="national_id" :selectedYear="selectedYear" @setWeekView="setWeekView" :selectedMonth="selectedMonth" :entityIds="currentEntityIds" :entity="widget.widgetSettings.calendarEntity.entity" :isMobile="isMobile || isCustomMobile ? true : false" :http="http"/>
        </el-col>
      </el-row>
    </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Calendar from './calendar';
import CalendarWeekdays from './calendar/year-table/weekdays';

export default {
  components: {
    // Datepicker
    Calendar,
    // Week,
    CalendarWeekdays
  },
  props: ['breakpoint', 'widget', 'season', 'http', 'national_id'],
  data() {
    return {
      popoverShow: false,
      currentFixtures: undefined,
      regularSeasonRounds: undefined,
      get: _.get,
      upperCase: _.upperCase,
      capitilize: _.capitalize,
      date: moment().format('YYYY-MM-DD'),
      pickedDate: undefined,
      entityId: [],
      selectedRoundBeforeHandle: '1',
      selectedDateBeforeHandle: undefined,
      allTeams: [],
      memberships: [],
      events: [],
      isWeekView: false,
      isCustomMobile: false,
      isMobile: false,
    };
  },
  mounted() {
    console.log(this.widget.widgetSettings.calendarEntity.entityIds);
    if (this.widget.widgetSettings.calendarEntity.entityIds.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      this.entityId = [this.entityIds[0]._id];
    } else {
      this.entityId = [];
    }
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        this.atTopPage = true;
        this.showFilter = false;
      } else {
        this.atTopPage = false;
        this.showFilter = true;
      }
    };
    // evnet listener for window resize
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    getPresetDimension() {
      if (this.widget.widgetSettings.hasCustomHeight) {
        if (this.widget.widgetSettings.hasCustomWidth) {
          if (this.widget.widgetSettings.customWidth < 600) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.isCustomMobile = true;
          }
          return `max-width:${this.widget.widgetSettings.customWidth}px; 
          height:${this.widget.widgetSettings.customHeight}px; 
          overflow-y:auto;
          overflow-x:hidden;
          `;
        }
        return ` height:${this.widget.widgetSettings.customHeight}px; 
        overflow-y:auto;
        overflow-x:hidden;
        `;
      }
      if (_.get(this.widget, 'widgetSettings.hasCustomWidth') && !this.widget.widgetSettings.hasCustomHeight) {
        if (this.widget.widgetSettings.customWidth < 600) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isCustomMobile = true;
        }
        return `max-width:${this.widget.widgetSettings.customWidth}px;`;
      }
      return '';
    },
    entities() {
      const entityList = [
        { label: 'Memberships', value: 'membership', module: 'memberships' },
        { label: 'Events', value: 'event', module: 'events' }
      ];
      const entities = [];
      entityList.forEach((entity) => {
        if (this.hasModule(entity.module)) {
          entities.push({
            label: entity.label, value: entity.value
          });
        }
      });

      return entities;
    },
    selectedYear() {
      return this.season || this.$store.getters['season/currentSeason'];
    },
    selectedMonth() {
      return this.widget.widgetSettings.calendarEntity.month;
    },
    entityIds() {
      // Filter out the entityIds that are in widgetSettings.calendarEntity.memberships and widgetSettings.calendarEntity.events that are isActive === false
      const memberships = _.get(this.widget, 'widgetSettings.calendarEntity.memberships', []);
      const events = _.get(this.widget, 'widgetSettings.calendarEntity.events', []);
      const membershipsFiltered = memberships.filter((m) => m.isActive);
      const eventsFiltered = events.filter((e) => e.isActive);
      const entities = [
        ...membershipsFiltered.map((m) => ({ _id: m._id, name: m.name, entity: 'membership' })),
        ...eventsFiltered.map((e) => ({ _id: e._id, name: e.name, entity: 'event' }))
      ];
      // console.log({
      //   memberships,
      //   events,
      //   membershipsFiltered,
      //   eventsFiltered,
      //   entities,
      // });
      return entities;
      // return this.widget.widgetSettings.calendarEntity.entityIds;
    },
    currentEntityIds() {
      return this.entityId;
    }
  },
  methods: {
    handleResize() {
      // if (window.innerWidth < 400) {
      //   this.isMobile = true;
      // } else {
      //   this.isMobile = false;
      // }
      // get width of the  class widget-container
      const widgetContainer = document.querySelector('.widget-container');
      if (widgetContainer) {
        const widgetContainerWidth = widgetContainer.offsetWidth;
        this.widgetContainerWidth = widgetContainerWidth;
        console.log('widgetContainerWidth', this.widgetContainerWidth);
        if (widgetContainerWidth <= 600) {
          this.isMobile = true;
        } else if (widgetContainerWidth > 650) {
          this.isMobile = false;
        }
      }
    },
    hasModule(...modules) {
      if (this.clearance === 1) return true;
      const userModules = this.$store.getters['user/module'];
      return modules.every((m) => _.get(userModules, m) === true);
    },
    setWeekView(bool) {
      this.isWeekView = bool;
    }
  },
  watch: {
    'widget.widgetSettings.calendarEntity.entityIds': {
      deep: true,
      handler(entityIds) {
        if (entityIds.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          this.entityId = [{ _id: entityIds[0]._id, entity: entityIds[0].entity }];
        } else {
          this.entityId = [];
        }
      }
    },
    entityId: {
      handler(val) {
        console.log(val.includes(0));
        if (val.includes(0)) {
          this.entityId = this.entityIds.map((e) => ({ _id: e._id, entity: e.entity }));
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-select .el-input__inner, .el-input .el-input__inner, .el-input-number .el-input__inner{
  border-radius: 10px;
  // border: solid 1px #fff;
}
::v-deep.el-select .el-input.is-focus .el-input__inner{
  background: $highlight;
  color: #fff;
}
::v-deep .el-card__body{
  padding: 0;
}
::v-deep .el-card__header{
  background: $secondary;
  color: #fff;
}

::v-deep .vdp-datepicker__calendar {
  border: none !important;
  font-size: 17px;
  // font-weight: normal;
  // font-stretch: normal;
  font-style: normal;
  // line-height: 2.35;
  // letter-spacing: normal;
  color: #333;
  // background-color: #eee;

  .cell{
    background-color: #eee;
    // margin: 0 0 1px;
    border: solid 1px #ccc;
  }
  .blank{
    opacity: 0.2; ;
    background-color: #eee;
  }
  .selected{
    background-color: $highlight !important;
    color:#fff;
    border: solid 1px $highlight !important;
  }
  .day-header{
      color: #333;
  opacity: 0.5;
  }
}
::v-deep .vdp-datepicker__calendar .cell.day-header {
  border: none !important;
  background-color: #fff;
}

::v-deep .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year{
  border: solid 1px #ccc;
  background-color: #fff;
}
::v-deep .vdp-datepicker__calendar header span{
 background: #fff;
}
::v-deep .vdp-datepicker__calendar header .prev:after {
  border-right: 6px solid #000;
  margin-left: -5px;
}
::v-deep .vdp-datepicker__calendar header .next:after {
  border-left: 6px solid #000;
  margin-left: 5px;
}
::v-deep .el-button{
  text-align: center;
  padding: 9px 12px 9px 12px;
  border-radius: 10px;
  font-size: 18px;
  color: #606266;
  // border: solid 1px $highlight;
  // background-color: white;
}
::v-deep .el-button--primary{
  text-align: center;
  padding: 9px 12px 9px 12px;
  border-radius: 10px;
  border: solid 1px $highlight;
  background-color: $highlight;
  color: #fff;
}

::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
  // width:32px;
  // height:40px;
  background: $highlight;
  border-radius: 100px;
  border: none !important;
  color: #fff !important;
  padding: 0 13px
}

::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
  border: none;
}
// ::v-deep.el-tabs--card > .el-tabs__header{
//   width:280px;
// }
::v-deep.el-tabs--card>.el-tabs__header {
  border: none;
  margin : 0;
  display: flex;
  justify-content: center;
  .el-tabs__item{
    border-radius: 100px;
    border: none !important;
    line-height:32px;
    padding: 0 13px;
    margin: 0 5px;
    font-size:15px;
    // width: 32px;
    height: 32px;
  }
}
::v-deep.el-tabs:not(.report-view) > .el-tabs__content > .el-tab-pane{
  padding: 0;
}
 .el-tabs__nav-scroll{
  display: flex;
  max-width: 37vw;
  // justify-content: center;
 }
::v-deep .el-tabs__nav-next {
  top:-5px;
  font-size: 16px;
  padding-left: 20px;
  // color: #000;
}
::v-deep .el-tabs__nav-prev  {
  top:-5px;
  font-size: 16px;
  margin-right: 20px;
  // check if disabled
}
::v-deep .el-icon-arrow-right:before {
  content: '\e791';
}
::v-deep .el-icon-arrow-left:before {
  content: '\e792';
}
::v-deep .el-icon-arrow-up:before {
  content: '\e78f';
}
::v-deep .el-icon-arrow-down:before {
  content: '\e790';
}

.widget-container{
  width: 80%;
  margin: 0px auto;
  .widget-filter{
    width: 100%;
    margin-bottom: 2.5rem;
    position: relative;
    .team-date-filter{
      .team-filter{
        flex-basis: 90%;
      }
      .date-filter{
        flex-basis: 10%;
        text-align: end;
      }
    }

    .tabs{
      width: 250px;
    }
    .tabs-desktop{
      width:250px;
    }
    .rounds-filter{
      padding: 1rem 0 0.5rem 0;
    }

    .entity-container {
      position: sticky;
      top: 0;
    }
  }
}

</style>
<style lang="scss" scoped>

::v-deep.el-select .el-input__inner, .el-input .el-input__inner, .el-input-number .el-input__inner{
  border-radius: 10px;
  // border: solid 1px #fff;
}
::v-deep.el-select .el-input.is-focus .el-input__inner{
  background: $highlight;
  color: #fff;
}

.is-disabled{
  /* pointer-events: none; */
  /* cursor: not-allowed; */
  /* color: black !important; */
}
.input-group{
  text-align: end;
  font-size: 25px;

}
/* .el-tabs__nav-scroll{ */
  /* display: flex;
  max-width: 10vw; */
  /* justify-content: center; */
 /* } */
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2){
  padding: 0 13px;
}

.calendar-container {
  overflow: auto;
  height: 600px;
}

</style>
