<template>
  <div class="search-bar">
    <h2 v-if="title">{{ title }}</h2>
    <div class="d-flex gap-05">
      <el-input
        v-model="searchQuery"
        placeholder="Enter your postcode"
        @keyup.enter.native="search"
        :style="{ maxWidth: '300px' }"
      />
      <el-button @click="search">Search</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: ['title'],
  data() {
    return {
      searchQuery: ''
    };
  },
  methods: {
    search() {
      if (!this.searchQuery || !this.searchQuery.length) return;
      this.$emit('search', this.searchQuery);
    }
  }
};
</script>
