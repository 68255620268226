import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import app from './app.js';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: 'assemble-sports-user',
  storage: window.localStorage,
  modules: ['app', 'national', 'user'],
});

export default new Vuex.Store({
  state: {
    rootLoading: false,
  },
  mutations: {
    loading(state, update) {
      state.rootLoading = update;
    },
  },
  actions: {
  },
  modules: {
    app,

  },
  plugins: [vuexLocal.plugin],
});
